import React, { useEffect, useState } from 'react';
import { Box,TextField,RadioGroup,Radio,FormControlLabel,FormControl,InputLabel,Select,MenuItem,Divider, Button, Typography, CircularProgress, Grid, Card, CardContent } from '@mui/material';
import { useLocation } from 'react-router-dom'; // For parsing the URL
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { tokens } from '../theme';  // Assuming tokens are for color styling
import { useTheme } from '@mui/material';
import { FaUser } from "react-icons/fa";
import { MdAddCircle, MdViewCompactAlt, MdAssignmentTurnedIn, MdListAlt } from "react-icons/md";
import API_URLS from '../summaryapi/apiUrls';  // Assuming you have the API URL constants
//import './AssignBooking.css';  

const AssignBooking = () => {
  const [decodedData, setDecodedData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation(); // To get the current URL

  useEffect(() => {
    // Extract the data from the URL
    const query = new URLSearchParams(location.search);
    const encodedData = query.get('data');

    if (encodedData) {
      // Decode the data (assuming the data was base64 encoded)
      const decodedData = atob(encodedData); // Decode the base64 string
      const params = new URLSearchParams(decodedData); // Parse the decoded string as a query string

      // Set the decoded data to state
      const customer_id = atob(params.get('customer_id'));
      const customer_booking_id = atob(params.get('customer_booking_id'));
      const service_provider_id = atob(params.get('service_provider_id'));

      setDecodedData({
        customer_id,
        customer_booking_id,
        service_provider_id,
      });

      // Fetch data based on the extracted ids
      fetchData(customer_id, customer_booking_id, service_provider_id);
    }
  }, [location]);

  const fetchData = async (customer_id, customer_booking_id, service_provider_id) => {
    try {
      // Fetch customer data
      const customerResponse = await axios.post(API_URLS.getCustomerById.url, JSON.stringify({ customer_id }));
      setCustomerData(customerResponse.data);

      // Fetch provider data
      const providerResponse = await axios.post(API_URLS.getProviderDetailsById.url, JSON.stringify({ service_provider_id }));
      setProviderData(providerResponse.data);

      // Fetch booking data
      const bookingResponse = await axios.post(API_URLS.getBookingDataById.url, JSON.stringify({ customer_booking_id }));
      setBookingData(bookingResponse.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setLoading(false);
    }
  };


  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "pending":
        return "bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-300"; // Use yellow for Pending (5 shades)
      case "assigned":
        return "bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300"; // Use blue for Assigned (5 shades)
      case "scheduled":
        return "bg-gradient-to-r from-indigo-500 via-indigo-400 to-indigo-300"; // Use indigo for Scheduled (5 shades)
      case "completed":
        return "bg-gradient-to-r from-green-500 via-green-400 to-green-300"; // Use green for Completed (5 shades)
      case "cancelled":
        return "bg-gradient-to-r from-red-500 via-red-400 to-red-300"; // Use red for Cancelled (5 shades)
      default:
        return "bg-gradient-to-r from-gray-500 via-gray-400 to-gray-300"; // Default background for unknown statuses (5 shades)
    }
  };

  const providers = [
    { id: 1, name: 'Provider A', phone: '123-456-7890', status: 'Assigned' },
    { id: 2, name: 'Provider B', phone: '987-654-3210', status: 'Assigned' },
    { id: 3, name: 'Provider C', phone: '555-666-7777', status: 'Unassigned' },
    { id: 4,name: "John Doe", service: "Plumbing", phone: "123 456 7890", status: "Assigned" },
    { id: 5,name: "Jane Smith", service: "Electrician", phone: "987 654 3210", status: "Assigned" },
    { id: 6,name: "Michael Johnson", service: "Plumbing", phone: "555 666 7777", status: "Assigned" },
    { id: 7,name: "Alice Brown", service: "Carpentry", phone: "111 222 3333", status: "Unassigned" },
    { id: 8,name: "Chris Davis", service: "Plumbing", phone: "444 555 6666", status: "Assigned" },
    { id: 9, name: "David Wilson", service: "Electrician", phone: "222 333 4444", status: "Unassigned" },
    { id: 10,name: "Megan Lee", service: "Carpentry", phone: "999 000 1111", status: "Unassigned" },
    { id: 11,name: "Chris Miller", service: "Electrician", phone: "123 789 4567", status: "Assigned" },
    { id: 12,name: "Laura White", service: "Plumbing", phone: "678 123 4567", status: "Assigned" },
    { id: 13,name: "Emma Green", service: "Carpentry", phone: "333 444 5555", status: "Unassigned" }
  ];

   // State to hold the selected provider
  const [selectedProvider, setSelectedProvider] = useState(null);

  // Handle the dropdown change event
  const handleProviderChange = (event) => {
    const selectedProviderName = event.target.value;
    const provider = providers.find((p) => p.name === selectedProviderName);
    setSelectedProvider(provider);
  };

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [visibleProviders, setVisibleProviders] = useState(6); // Show 7 records initially

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    setVisibleProviders(6); // Reset to show only 7 when filter changes
  };

  const filteredProviders = providers.filter((provider) => {
    if (selectedFilter === "assigned") return provider.status === "Assigned";
    if (selectedFilter === "unassigned") return provider.status === "Unassigned";
    return true;
  });

  // Get the visible subset of providers (first 7 or the number of visible providers)
  const displayedProviders = filteredProviders.slice(0, visibleProviders);

  
  return (
    <div style={{ display: "flex"}}>

    {/* Main Content (3/4 width) */}
    <div
      style={{
        width: "100%",
        padding: "15px",
        boxSizing: "border-box",
        overflowY: "auto",
        height: "100%"
      }}
    >
     <h2  className="mb-3 font-bold" style={{ fontWeight: 'bold', fontSize: '2.5rem',  backgroundImage: 'linear-gradient(to right,rgb(7, 71, 28),rgb(26, 255, 64),rgb(20, 250, 70), #32CD32, #2E8B57)',  WebkitBackgroundClip: 'text', color: 'transparent', }}> ASSIGN BOOKINGS </h2>

      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : decodedData ? (
        <div>
          
          <Card className="mb-6 p-3">
            
            <div className="flex justify-between items-center mb-4">

              <Typography variant="h3" className="text-primary-100 font-semibold">
                <span style={{ fontWeight: 'bold', marginLeft: '15px',}}>Booking Details</span>
              </Typography>

            
              <Typography variant="h6" className={`text-white  ${getStatusBackgroundColor(bookingData.data?.booking_status)} px-7 py-2 rounded-lg`}>
                {bookingData.data?.booking_status || "N/A"}
              </Typography>

              
            </div>

            <Grid item xs={12}>
              <hr className="border-gray-300" />
            </Grid>

            <CardContent>
              <Grid container spacing={2}>
                
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" className="text-primary-200">
                      <strong>Booking Code<span style={{ marginLeft: '7.7%' }}>: </span></strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className="text-primary-200">
                      {bookingData.data?.booking_code || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" className="text-primary-200">
                      <strong>Booking Date<span style={{ marginLeft: '8%' }}>: </span></strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className="text-primary-200">
                      {bookingData.data?.service_booked_date ?  new Date(bookingData.data?.service_booked_date).toLocaleDateString('en-CA') : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" className="text-primary-200">
                      <strong>Booking Price<span style={{ marginLeft: '7.6%' }}>: </span></strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className="text-primary-200">
                      ₹{bookingData.data?.booking_price || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" className="text-primary-200">
                      <strong>GST<span style={{ marginLeft: '14.8%' }}>: </span></strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className="text-primary-200">
                      ₹{bookingData.data?.GST || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" className="text-primary-200">
                      <strong>Other Charges<span style={{ marginLeft: '7.3%' }}>: </span></strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="body1" className="text-primary-200">
                      ₹{bookingData.data?.other_charges || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <hr className="border-gray-300" />
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" className="text-primary-200">
                    <strong>Total Payable Price<span style={{ marginLeft: '1.1%' }}>: </span></strong><span style={{ marginLeft: '1.1%' }}> </span> ₹{bookingData.data?.total_payable_price || "N/A"}
                  </Typography>
                </Grid>

              </Grid>
            </CardContent>
          </Card>

          {/* Action Button Section */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ color: colors.primary[100] }} gutterBottom>
              <strong>Customer Details</strong>
            </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* Left side: Customer Details and Assign Orders */}
            <Grid item xs={12} md={6}>
              {/* Customer Details */}
              {customerData && (
                <Card sx={{ marginBottom: 2, padding: 1 }}>
                  <CardContent>
                    <Grid container alignItems="center" spacing={2} sx={{ padding: 0 }}>
                      {/* Left side: Profile Image */}
                      <Grid item sx={{ marginRight: 2 }}>
                        <Card sx={{ width: 70, height: 70, borderRadius: '50%', overflow: 'hidden' }}>
                          <div style={{ width: '100%', height: '100%' }}>
                            {customerData.data?.profile_image ? (
                              <img
                                src={customerData.data?.profile_image}
                                alt="Customer Profile"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                size="5x"
                                style={{ width: '100%', height: '100%' }}
                              />
                            )}
                          </div>
                        </Card>
                      </Grid>

                      {/* Right side: Full Name */}
                      <Grid item xs>
                        <Typography variant="h6" sx={{ color: colors.primary[100] }}>
                          <span style={{ fontSize: '30px' }}> {customerData.data?.full_name || "N/A"}</span>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1, padding:2 }}>
                      <hr className="border-gray-300" />
                    </Grid>

                    <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', color: colors.primary[100], padding: 1 }}>
                      <strong>Name</strong><strong style={{ marginLeft: '0px' }}>:</strong> <span style={{ marginRight: '16%' }}>{customerData.data?.full_name || "N/A"}</span>
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', color: colors.primary[200], padding: 1 }}>
                      <strong>Email</strong><strong style={{ marginRight: '0px' }}>:</strong> <span style={{ marginRight: '15%' }}>{customerData.data?.email || "N/A"}</span>
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', color: colors.primary[200], padding: 1 }}>
                      <strong>Phone</strong><strong style={{ marginRight: '5px' }}>:</strong> <span style={{ marginRight: '20%' }}>{customerData.data?.mobile_number || "N/A"}</span>
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', color: colors.primary[200], padding: 1 }}>
                      <strong>Address</strong><strong style={{ marginRight: '15px' }}>:</strong> <span style={{ marginRight: '10.5%' }}>{customerData.data?.address || "N/A"}</span>
                    </Typography>
                  </CardContent>
                </Card>
              )}

              {/* Assign Orders */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h3" sx={{ color: colors.primary[100] }} gutterBottom>
                    <strong>Assign Orders</strong>
                  </Typography>
                </Grid>
              </Grid>


              <Card sx={{ marginBottom: 4, padding: 2 }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1, flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Typography variant="h5" sx={{ color: colors.primary[100], marginBottom: { xs: 1, sm: 0 } }} gutterBottom>
                      <strong>Available Providers</strong>
                    </Typography>

                    {/* Right Side: Search Box and Provider Dropdown */}
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
                      <TextField label="Search by Provider ID or Name" variant="outlined" size="small" sx={{ width: { xs: '100%', sm: 150, display: 'none' } }} />
                      <FormControl variant="outlined" size="small" sx={{ width: { xs: '100%', sm: 150 } }}>
                        <InputLabel>Provider</InputLabel>
                        <Select
                          label="Provider"
                          value={selectedProvider ? selectedProvider.name : ''}
                          onChange={handleProviderChange}
                        >
                          {providers.map((provider) => (
                            <MenuItem key={provider.id} value={provider.name}>
                              {provider.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />

                  {/* Display Selected Provider Details */}
                  {selectedProvider ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row',}}>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '70%' , mr: 1}}>
                        <strong>Provider Name</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                        <strong>:</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '60%', ml: 1 }}>
                        {selectedProvider.name}
                      </Typography>
                    </Box>
                    <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row',  }}>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '70%' , mr: 1}}>
                        <strong>Mobile</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                        <strong>:</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '55%' , mr: 1 }}>
                        {selectedProvider.phone}
                      </Typography>
                    </Box>
                    <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row',  }}>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '70%' , mr: 1}}>
                        <strong>Status</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                        <strong>:</strong>
                      </Typography>
                      <Typography variant="body1" sx={{ color: colors.primary[200], width: '55%' , mr: 1 }}>
                        {selectedProvider.status}
                      </Typography>
                    </Box>
                    <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                  </Box>
                
                  ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row',}}>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '60%' , mr: 1}}>
                      <strong>Provider Name</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                      <strong>:</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '50%', ml: 1 }}>
                    
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                  <Box sx={{ display: 'flex', flexDirection: 'row',  }}>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '60%' , mr: 1}}>
                      <strong>Mobile</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                      <strong>:</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '50%' , mr: 1 }}>
                      
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                  <Box sx={{ display: 'flex', flexDirection: 'row',  }}>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '60%' , mr: 1}}>
                      <strong>Status</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '300%' }}>
                      <strong>:</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.primary[200], width: '50%' , mr: 1 }}>
                      
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: 2, borderColor: 'border-gray-300' }} />
                </Box>
                  )}

                  {/* Assign Button */}
                  <Button 
                      variant="contained" 
                      color="primary" 
                      sx={{ alignSelf: 'flex-end' , 
                        backgroundColor: 'white',
                        border: '1px solid',
                        borderColor: '#006400', // Border color is success color
                        color: '#006400', // Text color to success color
                        '&:hover': {
                          background: 'linear-gradient(45deg, #006400,rgb(2, 88, 2), #00b300, #66cc66, #99ff99)',
                          fontWeight: 'bold', 
                          borderColor: '#006400', // Keep border color on hover
                          color: 'white', // Text color to success color on hover
                        },
                        '&:active': {
                          background: 'linear-gradient(45deg, #006400,rgb(3, 88, 3), #00b300, #66cc66, #99ff99)', // No background color on click
                          color: '#006400', // Text color to success color on click
                          borderColor: '#006400', // Keep border color on click
                        },}}
                      onClick={() => { /* Handle assign action */ }}
                    >
                      <MdAssignmentTurnedIn    style={{ marginRight: '8px', fontSize: '20px' }} />
                      Assign
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Right side: Service Provider List */}
            <Grid item xs={12} md={6}>
              <Card sx={{ marginBottom: 4 }}>
                <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h5" sx={{ color: '#000', fontSize: '24px', fontWeight: 'bold' }} gutterBottom>
                    Service Provider List
                  </Typography>
                  
                  {/* Radio buttons for filter */}
                  <RadioGroup row value={selectedFilter} onChange={handleFilterChange}>
                    <FormControlLabel value="all" control={<Radio sx={{ color: '#000' }} />} label="All" />
                    <FormControlLabel value="assigned" control={<Radio sx={{ color: '#228B22' }} />} label="Assigned" />
                    <FormControlLabel value="unassigned" control={<Radio sx={{ color: '	#D2042D' }} />} label="Unassigned" />
                  </RadioGroup>
              </Box>

                  <Divider sx={{ marginY: 1 }} />

                  {/* Search input */}
                  <TextField fullWidth label="Search Provider" variant="outlined" sx={{ marginBottom: 2, display: 'none' }} />

                  {/* Table of service providers */}
                  <Typography variant="h6" sx={{ marginBottom: 2,  display: 'none'  }}>
                    <strong>Live Providers:</strong>
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={4} >
                      <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 1 }}>Live Provider Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 10 }}>Mobile Number</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' , ml: 22}}>Status</Typography>
                    </Grid>
                  </Grid>

                 
                           {/* Scrollable container for providers */}
                  <Box sx={{ maxHeight: 580, overflowY: 'auto', marginBottom: 2 }}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                      {displayedProviders.map((provider, index) => (
                        <Grid item xs={12} key={index}>
                          <Card sx={{ margin: 0.5, padding: 0.3, height: '100%' }}>
                            <CardContent>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={4}>
                                  <Typography variant="body1">{provider.name}</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                  <Typography variant="body1">{provider.phone}</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="right">
                                  <Typography variant="body1">{provider.status}</Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}

                      
                    </Grid>
                    
                  </Box>
                  {/* "View More" Button */}
                  {filteredProviders.length > visibleProviders && (
                        <Grid item xs={12} textAlign="center">
                          <Button
                            variant="outlined"
                            onClick={() => setVisibleProviders(visibleProviders + 7)}
                          >
                            View More
                          </Button>
                        </Grid>
                      )}




                </CardContent>
              </Card>
            </Grid>
          </Grid>

        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  </div>


  );
};

export default AssignBooking;
