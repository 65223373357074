import React from "react";
import { Box, IconButton, Grid } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { FaEdit } from "react-icons/fa";
import { useTheme } from "@mui/material";
import API_URLS from "../summaryapi/apiUrls";

const SubCategoryReportForm = ({ subcategories, categories = [], onEditCategory }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const editIconColor = isDarkMode ? "#ffffff" : "#5D3FD3";

  const rows = subcategories.map((subcategory) => ({
    id: subcategory.subcategory_id,
    subcategory_id: subcategory.subcategory_id,
    category_id: subcategory.category_id,
    subcategory_name: subcategory.subcategory_name,
    subcategory_image: subcategory.subcategory_image,
    is_active: subcategory.is_active,
  }));

  return (
    <Box m="20px">
      <Box
        m="0px 0 0 0"
        height="auto"
        sx={{
          borderRadius: "12px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontSize: "14px",
            textAlign: "center",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#5D3FD3",
            borderBottom: "none",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center",
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",
            color: "#000",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#5D3FD3",
            color: "white",
          },
        }}
      >
        <Grid container spacing={2}>
          {rows.map((subcategory) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={subcategory.id}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {/* Active/Inactive Icon */}
                <Box
                  position="absolute"
                  top={-21}
                  right={-5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    style={{
                      color: subcategory.is_active === "1" ? "green" : "red",
                    }}
                    aria-label={subcategory.is_active === "1" ? "Mark as active" : "Mark as inactive"}
                  >
                    {subcategory.is_active === "1" ? <CheckCircle /> : < Cancel/>}
                  </IconButton>
                </Box>

                {/* Subcategory Image */}
                <img
                  src={subcategory.subcategory_image ? `${API_URLS.getSubCategoryImages.url}${subcategory.subcategory_image}` : '/path/to/fallback-image.jpg'}
                  alt="Subcategory"
                  style={{
                    width: 210,
                    height: 170,
                    objectFit: "cover",
                    borderRadius: "7%",
                    marginBottom: 10,
                  }}
                />
                
                {/* Subcategory Name */}
                <span style={{ fontSize: "13px" }}>{subcategory.subcategory_name}</span>

                {/* Display Category Name */}
                <span style={{ fontSize: "13px" }}>
                  Category: 
                  {categories.find((category) => category.category_id === subcategory.category_id)?.category_name || "Not Available"}
                </span>

                {/* Edit Icon */}
                <IconButton
                  onClick={() => onEditCategory(subcategory)}
                  style={{ color: editIconColor, marginTop: -30, marginLeft: 180 }}
                >
                  <FaEdit />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SubCategoryReportForm;
