import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Get URL params
import axios from "axios"; // For making API calls
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { 
  Box, 
  Typography, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination
} from "@mui/material";
import API_URLS from "../summaryapi/apiUrls";

const DistributorDetails = () => {
  const { distributor_id } = useParams();
  const [distributorDetails, setDistributorDetails] = useState(null);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false); // For controlling modal visibility

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch Distributor Details
  const fetchDistributorDetails = async () => {
    try {
      const distributorResponse = await axios.post(API_URLS.getDistributorById.url,
        JSON.stringify({ distributor_id: distributor_id }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const distributorData = distributorResponse.data.data;
      if (distributorData) {
        setDistributorDetails(distributorData);
      }
    } catch (err) {
      setError("Error fetching distributor details.");
    }
  };

  // Fetch data when the component is mounted
  useEffect(() => {
    fetchDistributorDetails();
  }, [distributor_id]);


  // Handle modal close
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box
      m="20px"
      sx={{
        overflowY: "auto",
        height: "calc(100vh - 80px)",
        paddingBottom: "20px",
        '&::-webkit-scrollbar': { display: 'none' },
        scrollbarWidth: 'none',
      }}
    >
      {/* Page Title */}
      <Typography
        variant="h4"
        component="h1"
        sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 2 }}
      >
        <h1 className="font-poppins font-bold text-center text-[30px]">
          {distributorDetails.organization_name} Details
        </h1>
      </Typography>

      {/* Distributor Info */}
      <Box
        mb={6}
        p={6}
        sx={{
          backgroundColor: colors.primary[400],
          borderRadius: "8px",
          boxShadow: 2,
        }}
      >
        <img
          className="w-96 h-48 object-cover mt-2 mx-auto"
          src={`${API_URLS.getDistributorImages.url}${distributorDetails.image1}`} // Default image if not found
          alt={`${distributorDetails.organization_name} Image`}
        />
        <Typography sx={{ color: colors.primary[100], marginTop: 2 }}>
          <strong>Full Name:</strong> {distributorDetails.full_name}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Organization Name:</strong> {distributorDetails.organization_name}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Email:</strong> {distributorDetails.email}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Mobile Number 1:</strong> {distributorDetails.mobile_number1}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Mobile Number 2:</strong> {distributorDetails.mobile_number2}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Address:</strong> {distributorDetails.address}, {distributorDetails.city}, {distributorDetails.pincode}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>About:</strong> {distributorDetails.about}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Latitude:</strong> {distributorDetails.latitude}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Longitude:</strong> {distributorDetails.longitude}
        </Typography>
        <Typography sx={{ color: colors.primary[100] }}>
          <strong>Distributor License:</strong> {distributorDetails.distributor_license}
        </Typography>
      </Box>

      {/* Additional Information */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle
          className="text-xl font-semibold text-center"
          sx={{ color: colors.primary[100] }}
        >
          Distributor Details
        </DialogTitle>
        <DialogContent className="p-6 space-y-4">
          {/* Example Details */}
          <Typography variant="body1" sx={{ color: colors.primary[100] }}>
            <strong>Distributor ID:</strong> {distributorDetails.distributor_id}
          </Typography>
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={handleCloseModal}
            color="primary"
            className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-red-600"
            sx={{
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DistributorDetails;
