import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const BarChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Define trendy color schemes for both light and dark mode
  // const trendyColors = theme.palette.mode === 'dark'
  //   ? ['#FF6F61', '#4ECDC4', '#FFB142', '#E4A9FF']  // Dark mode colors
  //   : ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']; // Light mode colors

  const trendyColors = theme.palette.mode === 'dark'
    ? ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']  // Dark mode colors
    : ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']; // Light mode colors


  return (
    <Box m="10px">
      <Box 
        height="300px" // Fixed height to prevent overflow
        width="100%"   // Ensure the chart takes full width of the container
        display="flex"
        justifyContent="center"
        alignItems="center" 
      >
        {/* BarChart Component merged here */}
        <ResponsiveBar
          data={data}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: "#000",
                },
              },
              legend: {
                text: {
                  fill: "#000",
                },
              },
              ticks: {
                line: {
                  stroke: "#000",
                  strokeWidth: 1,
                },
                text: {
                  fill: "#000",
                },
              },
            },
            legends: {
              text: {
                fill: "#000",
              },
            },
          }}
          keys={["bookings"]}
          indexBy="day"  // X-axis will represent the days of the week
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={trendyColors}  // Apply trendy colors to bar chart
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Day of the Week",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Bookings",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
        />
      </Box>
    </Box>
  );
};

export default BarChart;
