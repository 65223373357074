import React, { useState } from "react";
import { Link } from "react-router-dom";
import API_URLS from "../summaryapi/apiUrls"; // Assuming API URLs are stored in a separate file
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Importing icons for password visibility toggle
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
const Distributor = ({
  distributor_id,
  full_name,
  mobile_number1,
  mobile_number2,
  organization_name,
  city,
  address,
  image1,
  image2,
  about,
  email,
  is_active,
  is_available,
  distributor_license,
  password,
  latitude,
  longitude
}) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const statusText = is_active === "1" ? "Active" : "Inactive";
  const statusClass = is_active === "1" ? "text-green-500" : "text-red-500";

  const profileImageUrl = `${API_URLS.getdistributorImages.url}${image1}`; // Updated to distributor_image1
  const businessImageUrl = `${API_URLS.getdistributorImages.url}${image2}`; // Updated to distributor_image2
  
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle the password visibility state
  };

  return (
    <div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-600">
      <Link
        to={`/distributor/${distributor_id}`} // Updated service_provider_id to distributor_id
        className="max-w-xs rounded overflow-hidden bg-white" // Reduced max width and margin for compactness
      >
        {/* Business Image */}
        <div className="relative flex justify-center items-center"> {/* Added flex classes to center */}
          <img
            className="w-80% h-36 object-cover mt-6" // Reduced image width and height
            src={businessImageUrl}
            alt={`${organization_name} Business`} // Updated business_name to organization_name
          />
          {/* Profile Image (overlay at the top of the business image) */}
          <div className="absolute top-8 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img
              className="w-20 h-20 object-cover rounded-full border-4 border-white" // Reduced profile image size
              src={profileImageUrl}
              alt={`${full_name} Profile`}
            />
          </div>
          {/* Status Icon (Top Right Corner) */}
          <div className="absolute top-2 right-2">
            {is_active === "1" ? (
              <FiCheckCircle className="text-green-500 w-6 h-6" /> // Checkmark for active
            ) : (
              <FiXCircle className="text-red-500 w-6 h-6" /> // Crossmark for inactive
            )}
          </div>
        </div>
  
        {/* Content Section */}
        <div className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              <strong>{full_name}</strong>
            </h2>
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Mobile:</strong></p>
              <p className="text-gray-500 text-sm">{mobile_number1}</p>
            </div>
            {mobile_number2 && (
              <div className="flex justify-between">
                <p className="text-gray-500 text-sm"><strong>Alt Mobile:</strong></p>
                <p className="text-gray-500 text-sm">{mobile_number2}</p>
              </div>
            )}
             <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Email:</strong></p>
              <p className="text-gray-500 text-sm">{email.slice(0, 10)}...</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Address:</strong></p>
              <p className="text-gray-500 text-sm">{address.slice(0, 10)}...</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>City:</strong></p>
              <p className="text-gray-500 text-sm">{city}</p>
            </div>
       
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm">
                <strong>Business:</strong>
              </p>
              <p className="text-gray-500 text-sm">
                {organization_name.length > 5 ? organization_name.slice(0, 10) + "..." : organization_name}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm">
                <strong>Distributor License:</strong>
              </p>
              <p className="text-gray-500 text-sm">
                {distributor_license.length > 5 ? distributor_license.slice(0, 10) + "..." : distributor_license}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm">
                <strong>About:</strong>
              </p>
              <p className="text-gray-500 text-sm">
                {about.length > 5 ? about.slice(0, 10) + "..." : about}
              </p>
            </div>
          </div>

        </div>
      </Link>
  
      {/* Password section */}
      <div className="flex flex-row items-center justify-between mr-3 ml-3">
        <div className="flex items-center space-x-2">
          <p className="text-gray-500 text-sm">
            <strong>Password:</strong> {showPassword ? password : "******"}
          </p>
        </div>
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="text-sm text-blue-500 hover:text-blue-700"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </button>
      </div>
    </div>
  );
  
};

export default Distributor;
