import { Box } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const PieChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Define trendy color schemes for both light and dark mode
  // const trendyColors = theme.palette.mode === 'dark'
  //   ? ['#FF6F61', '#4ECDC4', '#FFB142', '#E4A9FF']  // Dark mode colors
  //   : ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']; // Light mode colors

    const trendyColors = theme.palette.mode === 'dark'
    ? ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']  // Dark mode colors
    : ['#1E90FF', '#FF6347', '#32CD32', '#FFD700']; // Light mode colors

  return (
    <Box m="10px">
      <Box 
        height="250px" // Fixed height to prevent overflow
        width="100%"   // Ensure the chart takes full width of the container
      >
        {/* PieChart Component merged here */}
        <ResponsivePie
          data={data}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: "#000",
                },
              },
              legend: {
                text: {
                  fill: "#000",
                },
              },
              ticks: {
                line: {
                  stroke: "#000",
                  strokeWidth: 1,
                },
                text: {
                  fill: "#000",
                },
              },
            },
            legends: {
              text: {
                fill: "#000",
              },
            },
          }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#000"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={false}
          arcLabelsRadiusOffset={0.4}
          arcLabelsSkipAngle={7}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          colors={trendyColors}  // Apply trendy colors to pie chart
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#000",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#000",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#000",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default PieChart;
