import React from "react";
import { Box, IconButton, Grid  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { FaEdit } from "react-icons/fa";
import { tokens } from "../theme";
import API_URLS from "../summaryapi/apiUrls";

const CategoryReportForm = ({ categories, onEditCategory }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {};
  const isDarkMode = theme.palette.mode === "dark";
  const editIconColor = isDarkMode ? "#ffffff" : "#5D3FD3";
  const blueAccent700 = colors.blueAccent?.[700] || "#0d47a1";

  const columns = [
    {
      field: "category_id ",
      headerName: "S.NO",
      flex: 0.2,
      hide: true,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {/* Category Image */}
          <img
            src={`${API_URLS.getCategoryImages.url}${params.row.category_image}`}
            alt="Category"
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              borderRadius: "50%",  // Makes the image round
              marginRight: 10, // Space between image and text
              marginTop: 10,
              marginBottom :10,
            }}
          />
          {/* Category Name */}
          <span>{params.row.category_name}</span>
        </div>
      ),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "start", // Centering cell data
    },
    {
      field: "sub_category_count", // New field for sub-category count
      headerName: "Sub-Category Count",
      renderCell: (params) => (params.row.sub_category_count || 0), // Show sub-category count
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "is_active",
      headerName: "Active",
      renderCell: (params) => (params.value === "1" ? "Yes" : "No"),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => (
        <IconButton
          onClick={() => onEditCategory(params.row)}
          style={{ color: '#5D3FD3' }}
        >
          <FaEdit />
        </IconButton>
      ),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
  ];

  const rows = categories.map((category) => ({
    id: category.category_id,
    category_id: category.category_id,
    category_name: category.category_name,
    category_image: category.category_image,
    is_active: category.is_active,
  }));

  return (
    <Box m="20px">
      <Box
        m="0px 0 0 0"
        height="auto"
        //marginTop={10}
        sx={{
          borderRadius: "12px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontSize: "14px", // Slightly increased font size
            textAlign: "center", // Ensuring cell data is centered
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: '#5D3FD3',
            borderBottom: "none",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center", // Ensuring headers are centered
            color: 'white'
          },
          "& .MuiDataGrid-virtualScroller": {
           // backgroundColor: colors.primary?.[400] || "#b3e5fc",
           backgroundColor: "white" || "#b3e5fc",
            color: '#000'
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: '	#5D3FD3',
            color: 'white'
          },
        }}
      >
      <Grid container spacing={2}>
          {rows.map((category) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={category.id}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  textAlign: "center",
                  position: "relative",
                }}
              >
          {/* Active/Inactive Icon */}
            <IconButton
                  style={{
                    position: "absolute",
                    top: -21,
                    left: "93%",
                    transform: "translateX(-50%)",
                    color: category.is_active === "1" ? "green" : "red", // Green for active, red for inactive
                  }}
                >
                  {category.is_active === "0" ? (
                    <Cancel />
                  ) : (
                    <CheckCircle />
                  )}
                </IconButton>

                {/* Category Image */}
                <img
                  src={`${API_URLS.getCategoryImages.url}${category.category_image}`}
                  alt="Category"
                  style={{
                    width: 210,
                    height: 170,
                    objectFit: "cover",
                    borderRadius: "7%",
                    marginBottom: 10,
                  }}
                />
                {/* Category Name */}
                <span style={{ fontSize: "13px",}}>{category.category_name}</span>
                {/* Edit Icon */}
                <IconButton
                  onClick={() => onEditCategory(category)}
                  style={{ color: editIconColor, marginTop: -30, marginLeft: 180 }}
                >
                  <FaEdit />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};


export default CategoryReportForm;