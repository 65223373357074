import React, { useEffect, useState,useRef  } from "react";
import Modal from "react-modal";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { TextField, Button, Switch, FormControlLabel, InputAdornment, IconButton } from "@mui/material";
import API_URLS from "../summaryapi/apiUrls";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Eye icons for toggle
import { FaMapMarkerAlt } from 'react-icons/fa';
import Compressor from "compressorjs";
import { saveAs } from 'file-saver';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

Modal.setAppElement("#root");

const ServiceProviderModal = ({ open, onClose, onSubmit, provider, fetchServiceProviders,resetForm  }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Get colors based on current theme mode
  const [imagePreviews, setImagePreviews] = useState({
    profile_image: "",
    business_image: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Manage submission state
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [markerPosition, setMarkerPosition] = useState({
    lat: "", // Default latitude
    lng: "", // Default longitude
  });
  const [formData, setFormData] = useState({
    full_name: "",
    mobile_number: "",
    alternate_mobile_number: "",
    business_name: "",
    city: "",
    address: "",
    about_business: "",
    profile_image: null,
    business_image: null,
    is_active: true,
    password: "", // Added password field
    vehicle_type: '2-wheeler', 
    latitude: "",
    longitude: "",
  });

  const handleVehicleTypeChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      vehicle_type: value,
    }));
  };
  
  useEffect(() => {
    if (provider) {
      // Fetch provider details if an existing provider is selected
      const fetchProviderDetails = async () => {
        try {
          const response = await axios.post(API_URLS.getProviderDetailsById.url, {
            service_provider_id: provider.service_provider_id,
          });
  
          if (response.data.status) {
            const data = response.data.data;
  
            setFormData({
              provider_id: provider.service_provider_id,
              full_name: data.full_name || "",
              mobile_number: data.mobile_number || "",
              alternate_mobile_number: data.alternate_mobile_number || "",
              business_name: data.business_name || "",
              city: data.city || "",
              address: data.address || "",
              about_business: data.about_business || "",
              profile_image: null,
              business_image: null,
              is_active: data.is_active === "1",
              password: data.password || "",
              latitude: data.latitude,
              longitude: data.longitude,
              vehicle_type: data.vehicle_type || "2-wheeler", // Default vehicle type
            });
  
            setImagePreviews({
              profile_image: `${API_URLS.getproviderImages.url}${data.profile_image}` || "",
              business_image: `${API_URLS.getproviderImages.url}${data.business_image}` || "",
            });
  

          } else {
            toast.error("Failed to fetch provider details.");
          }
        } catch (error) {
          toast.error("Error fetching provider details.");
        }
      };
  
      fetchProviderDetails();
    } else if (open && !provider) {
      // Reset form when opening the modal for a new provider
      setFormData({
        full_name: "",
        mobile_number: "",
        alternate_mobile_number: "",
        business_name: "",
        city: "",
        address: "",
        about_business: "",
        profile_image: null,
        business_image: null,
        is_active: true,
        password: "",
        latitude: "",
        longitude: "",
        vehicle_type: '2-wheeler', // Default vehicle type for new providers
      });
  
      setImagePreviews({ profile_image: "", business_image: "" });
  
      setMarkerPosition({
        lat: 0, // Reset to default coordinates for new provider
        lng: 0,
      });
    }
  }, [open, provider]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  useEffect(() => {
    console.log("useEffect triggered");
  
    // Listen for messages from the new window (map page)
    const handleMessage = (event) => { 
      // Ensure the message comes from the expected source (your app's origin)
      if (event.origin === window.location.origin) {
        const data = event.data;
  
        // Check if lat and lng are provided, then update the form data
        if (data.lat && data.lng) {
          setFormData((prevState) => ({
            ...prevState,
            latitude: data.lat,  // Update latitude in form data
            longitude: data.lng, // Update longitude in form data
          }));
        }
  
        if (data.address) {
          setFormData((prevState) => ({
            ...prevState,
            address: data.address, // Update address in form data
          }));
        }
  
        if (data.city) {
          setFormData((prevState) => ({
            ...prevState,
            city: data.city, // Update city in form data
          }));
        }
  
        if (data.provider_id) {
          // Decode provider_id from base64 and update the form data
          const decodedProviderId = atob(data.provider_id);  // Decode base64 to get the original provider id
          setFormData((prevState) => ({
            ...prevState,
            provider_id: decodedProviderId, // Update provider_id in form data
          }));
        }
      } else {
        console.log("Message received from unexpected origin:", event.origin);
      }
    };
  
    // Attach event listener for message events
    window.addEventListener("message", handleMessage);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  

  const openMapInNewTab = () => {
    // Check if the provider exists (new or existing)
    const address = formData.address ? btoa(encodeURIComponent(formData.address)) : "";
    const city = formData.city ? btoa(encodeURIComponent(formData.city)) : "";
    const providerId = provider && provider.service_provider_id 
      ? btoa(provider.service_provider_id) 
      : btoa("default_provider_id"); // Default id for missing provider
  
    // Construct the URL without latitude and longitude
    const url = provider
      ? `/Map-page?provider_id=${providerId}&address=${address}&city=${city}`
      : `/Map-page`;  // URL for new provider without lat/lng

  
    // Open the URL in a new tab
    window.open(url, "_blank");
  };
  

  const handleImageChange = (e, imageField) => {
    const { files } = e.target;
    if (files && files[0]) {
      setFormData({ ...formData, [imageField]: files[0] });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews({
          ...imagePreviews,
          [imageField]: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const formDataToSend = new FormData();
  
    if (formData.provider_id) {
      formDataToSend.append("service_provider_id", formData.provider_id);
    }
  
    // Append other form data
    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("alternate_mobile_number", formData.alternate_mobile_number);
    formDataToSend.append("business_name", formData.business_name);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("latitude", formData.latitude);
    formDataToSend.append("longitude", formData.longitude);
    formDataToSend.append("about_business", formData.about_business);
    // Append password and images if available
    if (formData.password && formData.password !== "******") {
      formDataToSend.append("password", formData.password);
    }
    if (formData.profile_image) {
      formDataToSend.append("profile_image", formData.profile_image);
    }
    if (formData.business_image) {
      formDataToSend.append("business_image", formData.business_image);
    }
    if (formData.is_active !== undefined) {
      formDataToSend.append("is_active", formData.is_active ? "1" : "0");
    }
  

    formDataToSend.append("vehicle_type", formData.vehicle_type);
    try {
      let response;
      if (provider) {
        response = await axios.post(API_URLS.UpdateServiceProvider.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        response = await axios.post(API_URLS.CreateServiceProvider.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
  
      if (response.data.status) {
        console.log("Form data before submit:", formData);
        toast.success(provider ? "Provider updated successfully!" : "Provider added successfully!");
        fetchServiceProviders();
        onClose();
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error("Error submitting data.");
    } finally {
      setIsSubmitting(false);
    }
  };


  
  const handleToggleChange = (e) => {
    if (e.target && e.target.checked !== undefined) {
      const isActive = e.target.checked;
      setFormData((prevState) => ({
        ...prevState,
        is_active: isActive,
      }));
    }
  };
  return (
<Modal
  isOpen={open}
  onRequestClose={onClose}
  contentLabel={provider ? "Edit Service Provider" : "Add Service Provider"}
  className="bg-white p-4 rounded-lg shadow-lg w-full sm:w-[90vw] md:w-[60vw] lg:w-[40vw] mx-auto relative"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
>
  {/* Close button */}
  <button
  onClick={onClose}
  className="absolute top-2 right-2 text-3xl text-gray-600 hover:text-gray-900 focus:outline-none"
>
  &times; {/* HTML entity for a cross (×) */}
</button>

  <h2 className="text-xl mb-4 text-center font-bold text-gray-900">
    {provider ? "Edit Service Provider" : "Add Service Provider"}
  </h2>
      <form
    onSubmit={handleSubmit}
    className="space-y-4 max-h-[80vh] overflow-y-auto pr-2"
  >
        {/* Full Name and Mobile Number in one row */}

        <div className="flex flex-col ml-2">
          <FormControl component="fieldset" fullWidth>
  <FormLabel
    component="legend"
    style={{ color: colors.primary[200], fontWeight: 700 }}
    className="block font-bold"
  >
    Vehicle Type
  </FormLabel>
  <RadioGroup
    name="vehicle_type"
    value={formData.vehicle_type} // Use formData.vehicle_type here
    onChange={handleVehicleTypeChange} // Updated to call handleVehicleTypeChange
    row
  >
    <FormControlLabel
      value="2-wheeler"
      control={<Radio />}
      label="2-Wheeler"
      style={{ color: colors.primary[100] }}
    />
    <FormControlLabel
      value="4-wheeler"
      control={<Radio />}
      label="4-Wheeler"
      style={{ color: colors.primary[100] }}
    />
  </RadioGroup>
</FormControl>

</div>


<div className="flex flex-col">
  <label className="text-sm font-bold text-gray-500 mb-1">Full Name</label>
  <TextField
    name="full_name"
    value={formData.full_name}
    onChange={handleInputChange}
    fullWidth
    required
    placeholder="Enter Full Name"
    sx={{
      backgroundColor: colors.primary[400],
      color: colors.primary[100],
      '& .MuiInputBase-input': {
        height: '1.6rem', // Adjust the height of the input text area
        padding: '4px 8px', // Adjust padding for a better appearance
      },
    }}
  />
</div>

<div className="grid grid-cols-2 gap-4">
<div className="flex flex-col">
  <label className="text-sm font-bold text-gray-500 mb-2">Mobile Number</label>
  <TextField
    name="mobile_number"
    value={formData.mobile_number}
    onChange={(e) => {
      // Restrict input to 10 digits
      if (e.target.value.length <= 10) {
        handleInputChange(e);
      }
    }}
    fullWidth
    required
    disabled
    placeholder="Enter Mobile Number"
    sx={{
      backgroundColor: colors.primary[400],
      color: colors.primary[100],
      '& .MuiInputBase-input': {
        height: '1.5rem', // Adjust the height of the input text area
        padding: '4px 8px', // Adjust padding for a better appearance
      },
    }}
  />
</div>


  {/* Alternate Mobile Number */}
  <div className="flex flex-col">
    <label className="text-sm font-bold text-gray-500 mb-2">Alternate Mobile Number</label>
    <TextField
      name="alternate_mobile_number"
      value={formData.alternate_mobile_number}
      onChange={(e) => {
        // Restrict input to 10 digits
        if (e.target.value.length <= 10) {
          handleInputChange(e);
        }
      }}
      fullWidth
      placeholder="Enter Alternate Mobile Number"
      sx={{
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
        '& .MuiInputBase-input': {
          height: '1.5rem', // Adjust the height of the input text area
          padding: '4px 8px', // Adjust padding for a better appearance
        },
      }}
    />
  </div>
</div>

       {/* Business Name */}
<div className="flex flex-col">
  <label className="text-sm font-bold text-gray-500 mb-2">Business Name</label>
  <TextField
    name="business_name"
    value={formData.business_name}
    onChange={handleInputChange}
    fullWidth
    required
    placeholder="Enter Business Name"
    sx={{
      backgroundColor: colors.primary[400],
      color: colors.primary[100],
      '& .MuiInputBase-input': {
        height: '1.5rem', // Adjust the height of the input text area
        padding: '4px 8px', // Adjust padding for a better appearance
      },
    }}
  />
</div>

{/* About Business */}
<div className="flex flex-col">
  <label className="text-sm font-bold text-gray-500 mb-2">About Business</label>
  <TextField
    name="about_business"
    value={formData.about_business}
    onChange={handleInputChange}
    fullWidth
    placeholder="Enter About Business"
    sx={{
      backgroundColor: colors.primary[400],
      color: colors.primary[100],
      '& .MuiInputBase-input': {
        height: '1.5rem', // Adjust the height of the input text area
        padding: '4px 8px', // Adjust padding for a better appearance
      },
    }}
  />
</div>

                
        <div className="flex items-center">
        <div className="flex-1 mr-2">
    <label className="text-sm font-bold text-gray-500 mb-2">Address</label>
    <TextField
      name="address"
      value={formData.address}
      onChange={handleInputChange}
      fullWidth
      placeholder="Enter Address"
      sx={{
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
        '& .MuiInputBase-input': {
          height: '1.5rem', // Adjust the height of the input text area
          padding: '4px 8px', // Adjust padding for a better appearance
        },
      }}  disabled 
    />


</div>

{/* Open Map Link - takes 30% width */}
<div className="w-1/3 ml-2">
  <a
    onClick={openMapInNewTab}  // Trigger map opening
    href="#"
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      textDecoration: 'none',  // Remove underline from the link
      color: 'red',  // Set the link color to red
      fontSize: '16px',  // Set font size
    }}
  >
    <FaMapMarkerAlt  // React Icon for location pin
      style={{
        fontSize: '24px',  // Icon size
        marginRight: '8px',  // Space between icon and text
      }}
    />
    Change Address In Map
  </a>
</div>


  </div>

  <div className="grid grid-cols-2 gap-4">
  {/* City Input - takes 70% width */}
  <div className="flex-1 mr-2">
    <label className="text-sm font-bold text-gray-500 mb-2">City</label>
    <TextField
      name="city"
      value={formData.city}
      fullWidth
      required
      placeholder="Enter City"
      sx={{
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
        '& .MuiInputBase-input': {
          height: '1.5rem', // Adjust the height of the input text area
          padding: '4px 8px', // Adjust padding for a better appearance
        },
      }} disabled
    />
  </div>

        {/* Password */}
        <div className="flex flex-col">
          <label className="text-sm font-bold text-gray-500 mb-2">Password</label>
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={handleInputChange}
            fullWidth
            placeholder="Enter Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }}
          />
        </div>  </div>

        <div className="hidden">
  <div className="flex flex-col">
    <label className="text-sm font-bold text-gray-500 mb-2">Latitude</label>
    <TextField
      name="latitude"
      value={formData.latitude} // This is bound to formData.latitude
      onChange={handleInputChange}
      fullWidth
      placeholder="Enter Latitude"
      sx={{
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
        '& .MuiInputBase-input': {
          height: '1.5rem',
          padding: '4px 8px',
        },
      }}
    />
  </div>

  <div className="flex flex-col">
    <label className="text-sm font-bold text-gray-500 mb-2">Longitude</label>
    <TextField
      name="longitude"
      value={formData.longitude} // This is bound to formData.longitude
      onChange={handleInputChange}
      fullWidth
      placeholder="Enter Longitude"
      sx={{
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
        '& .MuiInputBase-input': {
          height: '1.5rem',
          padding: '4px 8px',
        },
      }}
    />
  </div>
</div>




{/* Provider Image Fields in One Row */}
<div className="mb-4 flex space-x-4">
  {/* Profile Image Field */}
  <div className="w-1/2">
    <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Profile Image</label>
    <input
      type="file"
      name="profile_image"
      onChange={(e) => handleImageChange(e, 'profile_image')}
      className="w-full px-3 py-2 border rounded"
      style={{
        borderColor: colors.primary[300],
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
      }}
    />
    {/* Display Profile Image Preview */}
    {imagePreviews.profile_image && (
      <div className="mt-2">
        <img
          src={imagePreviews.profile_image}
          alt="Profile Preview"
          className="w-24 h-24 object-cover rounded"
          style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
        />
      </div>
    )}
  </div>

  {/* Business Image Field */}
  <div className="w-1/2">
    <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Business Image</label>
    <input
      type="file"
      name="business_image"
      onChange={(e) => handleImageChange(e, 'business_image')}
      className="w-full px-3 py-2 border rounded"
      style={{
        borderColor: colors.primary[300],
        backgroundColor: colors.primary[400],
        color: colors.primary[100],
      }}
    />
    {/* Display Business Image Preview */}
    {imagePreviews.business_image && (
      <div className="mt-2">
        <img
          src={imagePreviews.business_image}
          alt="Business Preview"
          className="w-24 h-24 object-cover rounded"
          style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
        />
      </div>
    )}
  </div>
</div>

        {/* Active status */}
        <div className="flex items-center">
          <FormControlLabel
            control={
              <Switch checked={formData.is_active} onChange={handleToggleChange} />
            }
            label="Active"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{
              backgroundColor: colors.greenAccent[400],
              "&:hover": {
                backgroundColor: colors.greenAccent[600],
              },
            }}
          >
            {isSubmitting ? "Submitting..." : provider ? "Update Provider" : "Add Provider"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ServiceProviderModal;
