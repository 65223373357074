import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Dialog,TextField, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";  // Assuming tokens are for color styling
import API_URLS from "../summaryapi/apiUrls";  // Ensure API URLs are correctly configured
import { Card, CardContent, CardMedia } from '@mui/material';
import Package from '../assets/images/engine1.jpg';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; 
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MdAddCircle, MdViewCompactAlt, MdAssignmentTurnedIn, MdListAlt } from "react-icons/md";
import './BookingDetails.css'; 
import { CiSquareRemove } from "react-icons/ci";
import { FaDeleteLeft } from "react-icons/fa6";
import { RiCloseCircleFill } from "react-icons/ri";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const BookingDetails = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState(bookings);
  const [filters, setFilters] = useState({ date: null });
  const [openModal, setOpenModal] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [selectedStatus, setSelectedStatus] = useState('');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(API_URLS.getAllBookingData.url);
      
      if (response.data.status === false) {
        setError("No bookings found.");
      } else {
        // Sort the bookings by created_date in descending order
        const sortedBookings = response.data.data.sort((a, b) => {
          const dateA = new Date(a.created_date); 
          const dateB = new Date(b.created_date); 
          return dateB - dateA; // Sort descending: more recent dates first
        });
  
        setBookings(sortedBookings);
      }
      
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch booking details.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = bookings;
  
    // Filter based on selectedDate
    if (selectedDate) {
      const selectedDateNormalized = new Date(selectedDate.setHours(0, 0, 0, 0));
      filtered = filtered.filter((booking) => {
        const bookingDate = new Date(booking.service_booked_date);
        const bookingDateNormalized = new Date(bookingDate.setHours(0, 0, 0, 0));
        return bookingDateNormalized.getTime() === selectedDateNormalized.getTime();
      });
    }
  
    // Filter based on selectedStatus
    if (selectedStatus && selectedStatus !== "All") {
      filtered = filtered.filter((booking) => booking.booking_status === selectedStatus);
    }
  
    // Filter based on searchTerm
    if (searchTerm) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((booking) => {
        return (
          (booking.itemsData && Array.isArray(booking.itemsData) && booking.itemsData.some(item => item.service_name && item.service_name.toLowerCase().includes(lowercasedSearchTerm))) ||
          (booking.booking_status && booking.booking_status.toLowerCase().includes(lowercasedSearchTerm)) ||
          (booking.package_name && booking.package_name.toLowerCase().includes(lowercasedSearchTerm)) ||
          (booking.booking_code && booking.booking_code.toLowerCase().includes(lowercasedSearchTerm))
        );
      });
    }
  
    // Set the filtered bookings
    setFilteredBookings(filtered);
  }, [selectedDate, selectedStatus, searchTerm, bookings]);
  
  
  useEffect(() => {
    fetchBookings();
  }, []);
  

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBooking(null);
  };

  const handleViewClick = (booking) => {
    setSelectedBooking(booking);
    setOpenModal(true);
  };

  const handleAssignClick = (row) => {
    // Extract the required IDs from the selected row
    const { customer_id, customer_booking_id, service_provider_id } = row;
  
    if (customer_id && customer_booking_id && service_provider_id) {

        // Encode the values using btoa
        const encodedCustomerId = btoa(customer_id);
        const encodedCustomerBookingId = btoa(customer_booking_id);
        const encodedServiceProviderId = btoa(service_provider_id);
  
        // Construct the query string with the encoded IDs
        const queryString = `customer_id=${encodedCustomerId}&customer_booking_id=${encodedCustomerBookingId}&service_provider_id=${encodedServiceProviderId}`;
        
        // Construct the full URL
        const url = `/assign-booking?data=${btoa(queryString)}`;
        
        // Open the /assign-booking page in a new tab
        window.open(url, '_blank');
      } else {
        console.error("No valid package or service ID found.");
      }
    }

    const columns = [
      {
        field: "booking_code",
        headerName: "Booking Code",
        flex: 0.2,
        headerClassName: "header-cell",
      },
      {
        field: "package_name",
        headerName: "Package/Service Name",
        flex: 0.3,
        renderCell: (params) => {
          const booking = params.row;
          return (
            <div style={{
              textAlign: 'center',
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '80px',
              padding: '10px',
              height: '100%',
              boxSizing: 'border-box',
            }}>
              {booking.package_id > 0 ? (
                <strong>
                  <span
                    style={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.primary[100],
                      padding: "2px 6px",
                      borderRadius: "4px",
                      marginRight: "5px",
                    }}
                  >
                    Package
                  </span>
                  {booking.package_name}
                </strong>
              ) : (
                <div>
                  {booking.itemsData?.map((item, idx) => (
                    <div key={idx} style={{ marginBottom: '5px' }}>
                      <strong>
                        <span
                          style={{
                            backgroundColor: colors.redAccent[700],
                            color: colors.primary[100],
                            padding: "2px 6px",
                            borderRadius: "4px",
                            marginRight: "5px",
                          }}
                        >
                          Service
                        </span>
                        {item.service_name || "N/A"}
                      </strong>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        },
        headerClassName: "header-cell",
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: "service_id",
        headerName: "Service ID",
        flex: 0.2,
        hide: true,  // This will hide the field from being displayed directly
        renderCell: (params) => {
          const booking = params.row;
          if (booking.package_id > 0) {
            return "N/A";  // If there's a package, there's no service_id to show
          } else {
            return booking.itemsData && booking.itemsData.length > 0 
              ? booking.itemsData.map((item, idx) => (
                  <div key={idx}>{item.service_id ? item.service_id : "N/A"}</div>
                ))
              : "N/A";
          }
        },
        headerClassName: "header-cell",
      },
      {
        field: "booking_price",
        headerName: "Booking Price",
        flex: 0.2,
        renderCell: (params) => `₹${params.row.booking_price}`,
        headerClassName: "header-cell",
      },
      {
        field: "booking_mode",
        headerName: "Booking Mode",
        flex: 0.2,
        renderCell: (params) => `${params.row.booking_mode}`,
        headerClassName: "header-cell",
      },
      {
        field: "total_payable_price",
        headerName: "Total Payable Price",
        flex: 0.3,
        renderCell: (params) => `₹${params.row.total_payable_price}`,
        headerClassName: "header-cell",
      },
      {
        field: "view_services",
        headerName: "View Services",
        renderCell: (params) => (
          <Button
            onClick={() => handleViewClick(params.row)}
            sx={{
              backgroundColor: colors.greenAccent[500],
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
          >
            View Details
          </Button>
        ),
        flex: 0.3,
        headerClassName: "header-cell",
      },
      {
        field: "assign",
        headerName: "Assign",
        renderCell: (params) => (
          <Button
            onClick={() => handleAssignClick(params.row)}  // Pass the selected row directly
            sx={{
              backgroundColor: colors.blueAccent[500],
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: colors.blueAccent[700],
              },
            }}
          >
            Assign
          </Button>
        ),
        flex: 0.2,
        headerClassName: "header-cell",
      },
      {
        field: "customer_id",  // Hidden field for customer ID
        headerName: "Customer ID",
        hide: true,  // Do not display this column in the table
      },
      {
        field: "customer_booking_id",  // Hidden field for customer booking ID
        headerName: "Customer Booking ID",
        hide: true,  // Do not display this column in the table
      },
      {
        field: "service_provider_id",  // Hidden field for service provider ID
        headerName: "Service Provider ID",
        hide: true,  
      },
    ];

  // Map the booking data to rows for the DataGrid
  const rows = bookings.map((booking) => ({
    id: booking.customer_booking_id,
    booking_code: booking.booking_code,
    package_name: booking.package_name || booking.service_name,
    full_name: booking.full_name,
    mobile_number: booking.mobile_number,
    service_booked_date: booking.service_booked_date,
    package_price: booking.package_price,
    booking_mode: booking.booking_mode,
    booking_price: booking.booking_price,
    total_payable_price: booking.total_payable_price,
    itemsData: booking.itemsData,
    package_id: booking.package_id,
    service_id: booking.service_id,
    customer_id: booking.customer_id,
    customer_booking_id: booking.customer_booking_id,
    service_provider_id: booking.service_provider_id,
  }));

  
  return (
    <Box m="30px 0 0 0" margin={3}>

     <h3 className="text-center mb-4" style={{
  fontSize: '28px',
  backgroundImage: 'linear-gradient(45deg, #5D3FD3, #6A42D7, #7A4AC7, #8A52B7, #9A5AB7,rgb(174, 127, 182),rgb(202, 191, 206))',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  fontWeight: 'bold'
}}>
  Booking Report
      </h3>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
  
        {/* Left side (Buttons) */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => setViewMode('grid')}
              variant={viewMode === 'grid' ? 'contained' : 'outlined'}
              sx={{ marginRight: 2, fontWeight: 'bold', height: '40px' }}
            >
              <MdViewCompactAlt style={{ marginRight: '8px', fontSize: '20px' }} />
              Grid View
            </Button>
          <Button
            onClick={() => setViewMode('list')}
            variant={viewMode === 'list' ? 'contained' : 'outlined'}
            sx={{ fontWeight: 'bold', height: '40px' }}
          >
            <MdListAlt style={{ marginRight: '8px', fontSize: '20px' }} />
            List View
          </Button>
          <Button
            onClick={() => {
              setFilters({ ...filters, date: null });
              window.location.reload();
            }}
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginLeft: '10px',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: '#5D3FD3',
              color: '#5D3FD3',
              '&:hover': {
                background: 'linear-gradient(45deg, #1E1350, #3C2A94, #5D3FD3, #7C62E2, #B89EFF)',
                borderColor: '#5D3FD3',
                color: 'white',
              },
              '&:active': {
                background: 'linear-gradient(45deg, #3C2A94, rgb(99, 74, 197), rgb(153, 133, 207))',
                color: '#5D3FD3',
                borderColor: '#5D3FD3',
              },
              height: '40px',
            }}
          >
            <FaDeleteLeft  style={{ marginRight: '8px', fontSize: '20px' }}/>
            Remove Filters
          </Button>
        </div>

  {/* Right side (Filters and Search) */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '30%', borderColor: '#000' }}>
  <LocalizationProvider dateAdapter={AdapterDateFns} className="custom-date-picker" style={{ flex: 1 }}>
    <DatePicker
      value={selectedDate}
      onChange={(newDate) => setSelectedDate(newDate)}
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>

  <FormControl sx={{ width: '100%', flex: 1 }}>
    <InputLabel id="booking-status-label">Booking Status</InputLabel>
    <Select
      labelId="booking-status-label"
      value={selectedStatus}
      onChange={(e) => setSelectedStatus(e.target.value)}
      label="Booking Status"
      style={{ borderRadius: '4px', fontWeight: 'bold' }}
      className="orderstatus"
    >
      <MenuItem value="All">All</MenuItem>
      <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="Completed">Completed</MenuItem>
      <MenuItem value="Scheduled">Scheduled</MenuItem>
      <MenuItem value="Assigned">Assigned</MenuItem>
      <MenuItem value="Cancelled">Cancelled</MenuItem>
    </Select>
  </FormControl>


  <TextField
  label="Search"
  variant="outlined"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
  className="search"
  sx={{
    width: '100%',
    flex: 1,
    '& .MuiInputBase-root': {
      padding: '9.5px 14px', // Default padding for root element
    },
    '& .MuiOutlinedInput-input': {
      font: 'inherit',
      letterSpacing: 'inherit',
      color: 'currentColor',
      padding: '4px 0 5px', // Custom padding for the input element
      border: '0',
      boxSizing: 'content-box',
      background: 'none',
      height: '0.4375em',
      margin: 0,
      WebkitTapHighlightColor: 'transparent',
      display: 'block',
      minWidth: 0,
      width: '100%',
      animationName: 'mui-auto-fill-cancel',
      animationDuration: '10ms',
    },
  }}
/>
</div>
</div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          {viewMode === 'grid' ? (
            // Grid View
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredBookings.map((booking) => (
            <Card key={booking.customer_booking_id} sx={{ width: "100%", maxWidth: 350, borderRadius: 5, boxShadow: 3, padding: 2 }}>
              <CardContent>
                {/* Booking Code and Package/Service display side by side */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: "bold", color: colors.primary[100] }}>
                  Booking Code: {booking.booking_code}
                </Typography>
                {/* Package or Service display logic */}
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Typography 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    fontWeight: 'bold',  
                    marginTop: '-30px',
                    background: booking.booking_status === 'Cancelled' 
                      ? 'linear-gradient(to left,rgb(255, 21, 4),rgb(248, 50, 50), rgb(255, 0, 0))'  // Red shades for Cancelled
                      : booking.booking_status === 'Completed'
                      ? 'linear-gradient(to left,rgb(3, 199, 107), rgb(63, 248, 162),rgb(175, 235, 207))'  // Green shades for Completed
                      : booking.booking_status === 'Scheduled'
                      ? 'linear-gradient(to left, #00B0FF, #64B5F6)'  // Sky blue shades for Scheduled
                      : booking.booking_status === 'Assigned'
                      ? 'linear-gradient(to left, #607D8B,rgb(138, 155, 163))'  // Gray shades for Assigned
                      : booking.booking_status === 'pending'
                      ? 'linear-gradient(to left, #FF9800, #FF5722)'  // Orange shades for Pending
                      : booking.booking_status === 'Pending'
                      ? 'linear-gradient(to left, #FFBF00,rgb(245, 201, 68), rgb(238, 208, 120))'  // Orange shades for Pending
                      : 'transparent',  // Default to transparent if none of the above
                    color: 'white', 
                    borderRadius: '4px',  
                    padding: '5px 5px'
                  }}
                >
                  {booking.booking_status}
                </Typography>
                </div>
                </div>
                  <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                  <strong>{booking.package_id > 0 ? "Package Name:" : "Service Name:"}</strong>  
                  {booking.package_id > 0 
                    ? (
                        <>
                          {booking.package_name || "N/A"} 
                          <strong style={{ fontWeight: 'bold', color: 'black', fontSize: '0.8rem' }}>(Package)</strong>
                        </>
                      ) 
                    : (
                        booking.itemsData?.length > 0
                          ? booking.itemsData.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <span style={{ marginBottom: '5px' }}>
                                  {item.service_name || "N/A"}
                                </span>
                                <strong style={{ fontWeight: 'bold', color: 'black', fontSize: '0.8rem' }}>(Service)</strong>
                              </React.Fragment>
                            ))
                          : "No services available"
                      )
                  }
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                  <strong>Booking Price:</strong> ₹{booking.booking_price}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                  <strong>Total Payable:</strong> ₹{booking.total_payable_price}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                <strong>Booking Date:</strong> {new Date(booking.service_booked_date).toISOString().split('T')[0]}
              </Typography>
                <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          
          <Button
            onClick={() => handleViewClick(booking)}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              fontWeight: 'bold', 
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: '#5D3FD3', // Border color is success color
              color: '#5D3FD3', // Text color to success color
              '&:hover': {
                background: 'linear-gradient(45deg, #1E1350, #3C2A94, #5D3FD3, #7C62E2, #B89EFF)',
                fontWeight: 'bold', 
                borderColor: '#5D3FD3', // Keep border color on hover
                color: 'white', // Text color to success color on hover
              },
              '&:active': {
                background: 'linear-gradient(45deg, #3C2A94,rgb(99, 74, 197),rgb(153, 133, 207))', // No background color on click
                color: '#5D3FD3', // Text color to success color on click
                borderColor: '#5D3FD3', // Keep border color on click
              },
            }}
          >
            <MdViewCompactAlt   style={{ marginRight: '8px', fontSize: '20px' }} />
            View Details
          </Button>
          <Button
            onClick={() => handleAssignClick(booking)}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              fontWeight: 'bold', 
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: '#006400', // Border color is success color
              color: '#006400', // Text color to success color
              '&:hover': {
                background: 'linear-gradient(45deg, #006400,rgb(2, 88, 2), #00b300, #66cc66, #99ff99)',
                fontWeight: 'bold', 
                borderColor: '#006400', // Keep border color on hover
                color: 'white', // Text color to success color on hover
              },
              '&:active': {
                background: 'linear-gradient(45deg, #006400,rgb(3, 88, 3), #00b300, #66cc66, #99ff99)', // No background color on click
                color: '#006400', // Text color to success color on click
                borderColor: '#006400', // Keep border color on click
              },
            }}
          >
             <MdAssignmentTurnedIn    style={{ marginRight: '8px', fontSize: '20px' }} />
            Assign
          </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
          ) : (
            // List View
            <div style={{ height: 650, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns.map((col) => ({
              ...col,
              renderCell: (params) => (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {col.renderCell ? col.renderCell(params) : params.value}
                </div>
              ),
              headerClassName: "header-cell",
              headerAlign: 'center',
              align: 'center',
            }))}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: `2px solid ${colors.primary[200]}`,
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "bold",
                fontSize: "16px",
                color: colors.primary[100],
                textAlign: "center",
              },
              "& .MuiDataGrid-cell": {
                textAlign: "center",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: colors.blueAccent[700],
                borderTop: `2px solid ${colors.primary[200]}`,
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                width: "auto",
              },
              "& .MuiDataGrid-columnHeader": {
                minWidth: 120,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textAlign: "center",
              },
            }}
          />
        </div>
          )}
        </>
      )}
      <Dialog open={openModal} onClose={handleCloseModal} sx={{ "& .MuiDialog-paper": { width: "25%" } }}>
      <DialogTitle
  className="text-xl font-semibold text-center"
  sx={{
    color: colors.primary[100],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft:'20px',
    marginRight:'20px',
    //width: '80%', // Adjust width as needed
    borderBottom: '2px solid #D3D3D3', // Horizontal line at the bottom
    paddingBottom: '8px', // Optional: to add space between content and underline
    marginBottom:'15px',
  }}
>
  <span style={{ flexGrow: 1, textAlign: 'left', fontSize: '26px', fontWeight:'bold', }}>Service Details</span> {/* Left side */}
  <RiCloseCircleFill style={{ fontSize: '30px' }} onClick={handleCloseModal} /> {/* Right side */}
</DialogTitle>

  <DialogContent className="p-3 space-y-2">
    <div className="space-y-4">
      {selectedBooking?.itemsData?.map((item, idx) => (
        <Card key={idx} sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: 130, borderRadius: "8px", boxShadow: 2 }}>
          
          {/* Left side: Image */}
          {item.service_image2 && (
            <div style={{ width: '30%', height: '100%', textAlign: 'center', marginTop: '15px', marginLeft: '25px' }}>
              <CardMedia
                component="img"
                image={`${API_URLS.getServiceImages.url}${item.service_image2}`}
                alt={item.service_name}
                sx={{
                  objectFit: 'cover',
                  borderRadius: "8px 8px 8px 8px",
                  height: '70%',
                  width: '100%',
                }}
              />
            </div>
          )}

          {/* Right side: Text Content */}
          <div style={{ width: '70%', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CardContent sx={{ textAlign: "left", padding: "0" }}>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold", color: colors.primary[100] }}>
                <span>Service Name: </span>{item.service_name}
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary[200], margin: "6px 0" }}>
              <span>Booking Price: </span>₹{item.service_price}
              </Typography>
            </CardContent>
          </div>
        </Card>
      ))}
    </div>
  </DialogContent>
  
</Dialog>

    </Box>
  );
};

export default BookingDetails;
