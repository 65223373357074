// src/pages/Dashboard.js
import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, Typography, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AiFillSignal } from "react-icons/ai";
import { FaUsers } from 'react-icons/fa';
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import BookingReport from "../../components/BookingReport"; // Import BookingReport component
import axios from "axios"; // Import axios to make API calls
import API_URLS from "../../summaryapi/apiUrls"; // Import API URLs
import Charts from "../../components/Charts"; // Import the updated Charts component
import { useNavigate } from "react-router-dom"; 
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EventNoteIcon from '@mui/icons-material/EventAvailable';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate(); 
  // State to manage the fetched data, loading state, and errors
  const [bookingsData, setBookingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({
    active_customers: "0",
    total_bookings: "0",
    active_service_providers:"0",
    total_customers:"0",
    total_service_providers:"0"
  });

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await axios.get(API_URLS.getAnalyticsData.url); // Replace with actual API call URL
        if (response.data.status) {
          setAnalyticsData(response.data.data); // Set fetched analytics data
        } else {
          setAnalyticsData({
            active_customers: "0",
            total_bookings: "0",
            active_service_providers:"0",
            total_customers:"0",
            total_service_providers:"0"
          });
        }
      } catch (err) {
        console.error("Error fetching analytics data:", err);
        setAnalyticsData({
          active_customers: "0",
          total_bookings: "0",
          active_service_providers:"0",
          total_customers:"0",
          total_service_providers:"0"
        });
      }
    };
  
    fetchAnalyticsData();
  }, []);
  // Fetch today's bookings from the API
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios(API_URLS.getTodayBookingData);
        if (response.data.status) {
          setBookingsData(response.data.data); // Set bookings data if success
        } else {
          setBookingsData([]); // If no data, set an empty array
        }
      } catch (err) {
      } finally {
        setLoading(false); // Turn off loading after the request is complete
      }
    };

    fetchBookings();
  }, []);

  const handleEditBooking = (booking) => {
    console.log("Edit booking:", booking);
  };


  const handleCardCustomerClick = () => {
    console.log("Navigating to booking details");
    navigate("/customer-details");
  };

  const handleCardClick = () => {
    console.log("Navigating to booking details");
    navigate("/booking-details");
  };


  const handleDistributorClickProviders = () => {
    console.log("Navigating to booking details");
    navigate("/distributor-page");
  };
  




  const handleCardClickProviders = () => {
    console.log("Navigating to booking details");
    navigate("/service-providers");
  };
  
const goalBookings = 1000; // Goal for total bookings
const goalCustomers = 1000; // Goal for total customers

// Calculate progress for total bookings and total customers
const bookingsProgress = (analyticsData.total_bookings / goalBookings) * 100;
const customersProgress = (analyticsData.active_customers / goalCustomers) * 100;
  return (
    <Box m="10px"       sx={{
      overflowY: "auto", 
      height: "calc(100vh - 80px)", 
      paddingBottom: "20px", 
      '&::-webkit-scrollbar': {
        display: 'none', 
      },
      scrollbarWidth: 'none', 
    }}>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center"
      sx={{
        flexDirection: { xs: "column", sm: "row" }, // Stack on mobile
        alignItems: { xs: "flex-start", sm: "center" }, // Align to start on mobile
      }}
      >
        <Header title="DASHBOARD"/>
        <Box>
          <Button
            sx={{
              backgroundColor: "white",
              color: "#5D3FD3",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              border: `1px solid #5D3FD3`,
               ":hover": { backgroundColor: "#5D3FD3",  color: "white", border: `1px solid #5D3FD3`,},
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" , ":hover": {
        color: "white", 
      },}} 
            />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        mt={2}
      >

<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out"
  display="flex"
  alignItems="center"
  justifyContent="center"
  onClick={handleCardClick}
  sx={{ cursor: 'pointer', background: 'linear-gradient(135deg, rgb(0, 0, 139), rgb(70, 130, 180), rgb(135, 206, 235))'    ,  borderRadius: '15px', padding: { xs: 2, sm: 3 }, }}  // Ensure it's clickable

>
  <StatBox
    title={analyticsData.total_bookings}
    subtitle="Total Bookings"
    progress={bookingsProgress}
    increase={`+${Math.round(bookingsProgress)}%`}
    icon={<EventNoteIcon className="text-blue-600 text-3xl" />} 
  />
</Box>
<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(75, 0, 130), rgb(138, 43, 226), rgb(216, 191, 216))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardClick}
>
  <StatBox
           title={analyticsData.pending_bookings}
    subtitle="Pending Bookings"
    progress="0.80"
    increase="+43%"
    icon={<HourglassEmptyIcon className="text-red-600 text-3xl" />
    }
  />
</Box>

<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(0, 100, 0), rgb(34, 139, 34), rgb(144, 238, 144))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardClickProviders}
>
  <StatBox
        title={analyticsData.total_service_providers}
    subtitle="Service Providers"
    progress="0.75"
    increase="+14%"
    icon={<BusinessCenterIcon className="text-green-600 text-3xl" />} // Green icon
  />
</Box>

<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(139, 0, 0), rgb(255, 69, 0), rgb(255, 160, 122))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardCustomerClick}
>
<StatBox
    title={analyticsData.total_customers} // Dynamic customer count
    subtitle="Customers"
    progress={customersProgress} // Dynamic progress based on count
    increase={`+${Math.round(customersProgress)}%`} // Display progress as increase percentage
    icon={<PersonAddIcon className="text-red-600 text-3xl" />} // Red icon
  />
</Box>

<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(255, 140, 0), rgb(255, 165, 0), rgb(255, 255, 224))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleDistributorClickProviders}
>
<StatBox
    title={analyticsData.total_distributors} // Dynamic customer count
    subtitle="Distributors"
    progress={customersProgress} // Dynamic progress based on count
    increase={`+${Math.round(customersProgress)}%`} // Display progress as increase percentage
    icon={<FaUsers className="text-white text-5xl" />}
  />
</Box>
<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(139, 69, 19), rgb(205, 133, 63), rgb(255, 239, 184))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardCustomerClick}
>
<StatBox
    title={analyticsData.total_customers} // Dynamic customer count
    subtitle="Customers"
    progress={customersProgress} // Dynamic progress based on count
    increase={`+${Math.round(customersProgress)}%`} // Display progress as increase percentage
    icon={<TrafficIcon className="text-red-600 text-3xl" />} // Red icon
  />
</Box>
<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(255, 20, 147), rgb(255, 105, 180), rgb(255, 182, 193))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardCustomerClick}
>
<StatBox
    title={analyticsData.total_customers} // Dynamic customer count
    subtitle="Customers"
    progress={customersProgress} // Dynamic progress based on count
    increase={`+${Math.round(customersProgress)}%`} // Display progress as increase percentage
    icon={<PointOfSaleIcon className="text-red-600 text-3xl" />} // Red icon
  />
</Box>
<Box
  gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3" }}
  className="hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out" // Hover scale and shadow effect with smooth transition
  display="flex"
  alignItems="center"
  justifyContent="center" sx={{ cursor: 'pointer' , background: 'linear-gradient(135deg, rgb(64, 64, 64), rgb(169, 169, 169), rgb(211, 211, 211))'    , borderRadius: '15px', padding: { xs: 2, sm: 3 },}} 
  onClick={handleCardCustomerClick}
>
<StatBox
    title={analyticsData.total_customers} // Dynamic customer count
    subtitle="Customers"
    progress={customersProgress} // Dynamic progress based on count
    increase={`+${Math.round(customersProgress)}%`} // Display progress as increase percentage
    icon={<EmailIcon className="text-red-600 text-3xl" />} // Red icon
  />
</Box>

      </Box>

    {/* ROW 3 - Pie and Bar Charts */}
    <Box
          gridColumn="span 12"
          display="flex"
          justifyContent="space-between"
          gap="20px"
          mt={4}
        >
          <Charts />
        </Box>

        {/* ROW 2 - Today Bookings */}
        <Box
  gridColumn="span 12"
  height="50vh"
  display="flex"
  flexDirection="column"
  justifyContent="flex-start"
  marginTop={-25}
>


  {loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <CircularProgress />
    </Box>
  ) : error ? (
    <Typography color="error">{error}</Typography>
  ) : (
    <BookingReport
      bookingsData={bookingsData} // Pass all bookings data to BookingReport
      onEditBooking={handleEditBooking} // Pass edit handler
    />
  )}
</Box>

    </Box>
  );
};

export default Dashboard;
