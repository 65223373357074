// ChartPie.tsx
import React from "react";
import { TEChart } from "tw-elements-react";

// Corrected declaration of ChartPie component using 'const'
const ChartPie = () => {
  return (
    <div className="w-full h-[400px] mx-auto p-4  rounded-lg ">
      <TEChart
        type="pie"
        data={{
          labels: [
            "One",
            "Two",
            "Three",
          ],
          datasets: [
            {
              label: "Traffic",
              data: [2112, 2343, 2545],
              backgroundColor: [
                "rgba(57, 81, 218, 0.86)",
                "rgba(247, 84, 9, 0.94)",
                "rgb(134, 49, 204)",
              ],
            },
          ],
        }}
      />
    </div>
  );
};

export default ChartPie;
