import React, { useState, useEffect } from "react";
import { Typography, Button, Switch, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import SubCategoryReportForm from "../../components/SubCategoryReportForm";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { MdAddCircle } from "react-icons/md";
import API_URLS from "../../summaryapi/apiUrls";
import './SubCategoryForm.css';

Modal.setAppElement("#root");

const SubCategoryForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [previewImage, setPreviewImage] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [subcategoryName, setSubCategoryName] = useState("");
  const [subcategoryImage, setSubCategoryImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [subcategoryId, setSubcategoryId] = useState(null);

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await fetch(API_URLS.getAllCategories.url);
      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        setCategories(data.data);
      } else {
        console.error("Invalid categories data format", data);
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
      toast.error("Failed to load categories.");
    }
  };

  // Fetch subcategories
  const fetchSubcategories = async () => {
    try {
      const response = await fetch(API_URLS.getAllSubcategory.url);
      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        const sortedSubcategories = data.data.sort((a, b) => {
          const dateA = new Date(a.created_date);
          const dateB = new Date(b.created_date);
          return dateB - dateA;
        });
        setSubcategories(sortedSubcategories);
      } else {
        console.error("Invalid subcategories data format", data);
      }
    } catch (err) {
      console.error("Error fetching subcategories:", err);
      toast.error("Failed to load subcategories.");
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
  }, []);

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [modalOpen]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSubCategoryImage(file);
    }
  };

  const handleAddOrUpdateSubcategory = async () => {
    if (!subcategoryName || !selectedCategoryId) {
      toast.error("Please fill all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("subcategory_name", subcategoryName);
    formData.append("is_active", isActive ? "1" : "0");
    formData.append("category_id", selectedCategoryId);
    if (subcategoryImage) {
      formData.append("image", subcategoryImage);
    }

    if (subcategoryId) {
      formData.append("subcategory_id", subcategoryId);
    }

    try {
      const response = await fetch(
        editingSubcategory ? API_URLS.updateSubcategory.url : API_URLS.createSubcategory.url,
        {
          method: editingSubcategory ? API_URLS.updateSubcategory.method : API_URLS.createSubcategory.method,
          body: formData,
        }
      );

      const result = await response.json();
      if (result.status) {
        await fetchSubcategories();
        toast.success(editingSubcategory ? "Subcategory updated successfully!" : "Subcategory added successfully!");
        setModalOpen(false);
        resetForm();
      } else {
        toast.error(result.message || "Failed to save subcategory.");
      }
    } catch (err) {
      toast.error("Error saving subcategory: " + err.message);
    }
  };

  const openEditModal = (subcategory) => {
    if (subcategory) {
      setSubCategoryName(subcategory.subcategory_name);
      setSelectedCategoryId(subcategory.category_id);
      setIsActive(subcategory.is_active === "1");
      setEditingSubcategory(subcategory);
      setSubcategoryId(subcategory.subcategory_id);
      setModalOpen(true);
    }
  };

  const resetForm = () => {
    setSubCategoryName("");
    setSubCategoryImage(null);
    setIsActive(true);
    setSelectedCategoryId("");
    setEditingSubcategory(null);
    setSubcategoryId(null);
  };

  return (
    <div className="w-90">
      <div
        className="font-poppins font-bold text-center text-[30px] mt-5 bg-gradient-to-r from-[#5F3FD3] via-[#2A1E63] to-[#000000] text-transparent bg-clip-text"
        style={{ color: theme.palette.mode === 'dark' ? 'white' : 'transparent' }}
      >
        Subcategories
      </div>

      <div className="flex justify-end mb-[16px] w-[97%]">
        <Button
          variant="contained"
          onClick={() => {
            resetForm();
            setModalOpen(true);
          }}
          color="success"
          size="large"
          className="w-43 text-white font-semibold pr-4 font-poppins"
        >
          <MdAddCircle style={{ marginRight: '8px', fontSize: '20px' }} />
          Add Subcategory
        </Button>
      </div>

      <SubCategoryReportForm
        subcategories={subcategories}
        categories={categories}
        onEditCategory={openEditModal}
      />

<Modal
  isOpen={modalOpen}
  onRequestClose={() => setModalOpen(false)}
  contentLabel={editingSubcategory ? "Edit Subcategory" : "Add New Subcategory"}
  className="modal-content"
  overlayClassName="modal-overlay"
>
    <Typography variant="h4" className="modalTitle">
      {editingSubcategory ? "Edit Subcategory" : "Add New Subcategory"}
    </Typography>
    <FormControl fullWidth>
  <label className="block text-sm font-medium text-gray-700">
    Select Category
  </label>
  <Select
  labelId="category-label"
  value={selectedCategoryId || ""}  // Ensures the placeholder text is shown by default
  onChange={(e) => setSelectedCategoryId(e.target.value)}
  MenuProps={{
    style: { zIndex: 4000 },
    getContentAnchorEl: null,
  }}
  className="dropdownField text-gray-700 dark:text-gray-300 bg-white dark:bg-black border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
  displayEmpty
  sx={{
    height: '36px', // Reduce height
    color: selectedCategoryId ? 'text-gray-700' : 'inherit', // Set selected text color
    backgroundColor: selectedCategoryId ? 'text-gray-100' : 'inherit', // Light gray background when selected in both modes
    '& .MuiSelect-select': {
      color: selectedCategoryId ? 'text-gray-700' : 'inherit', // Ensure the selected text color
      backgroundColor: selectedCategoryId ? 'text-gray-100' : 'inherit', // Ensure background color is set
    },
    // Dark mode background for the input field
    '& .MuiSelect-root': {
      backgroundColor: 'inherit', // Inherit background color from select styles
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'inherit', // Ensure the input area background color is inherited
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'inherit', // Ensure the base input color is inherited
    },
    // For dark mode (black background for input field)
    '&.Mui-focused .MuiOutlinedInput-root': {
      backgroundColor: 'black', // Black background when focused in dark mode
    },
    '&.MuiSelect-root': {
      backgroundColor: 'black', // Black background when dark mode is active
    },
  }}
>
  {/* Render placeholder only when no value is selected */}
  {!selectedCategoryId && (
    <MenuItem value="" disabled className="text-gray-700 dark:text-gray-300">
      Select Category
    </MenuItem>
  )}

  {/* Map through categories and display them as selectable options */}
  {categories.map((category) => (
    <MenuItem key={category.category_id} value={category.category_id} className="text-gray-700 dark:text-gray-300">
      {category.category_name}
    </MenuItem>
  ))}
</Select>

</FormControl>

    <div className="space-y-4 mt-1">
      <div className="inputGroup">
        <label htmlFor="subcategory-name">Subcategory Name</label>
        <input
          type="text"
          id="subcategory-name"
          value={subcategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
          className="inputField"
        />
      </div>

      <div className="inputGroup fileInputContainer">
  <label className="block text-sm font-medium text-gray-700">
    Subcategory Image
  </label>
  <input
    type="file"
    accept="image/*"
    onChange={handleImageChange}
    className="mt-1 block w-full text-sm text-gray-500 file:mr-2 file:py-2 file:px-4 file:border file:border-transparent file:rounded-full file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600"
  />
  {subcategoryImage && (
    <img
      src={URL.createObjectURL(subcategoryImage)}
      alt="Category Preview"
      className="mt-2 rounded-lg object-cover w-24 h-24"
    />
  )}
  {editingSubcategory && !subcategoryImage && editingSubcategory.subcategory_image && (
    <img
      src={`${API_URLS.getSubCategoryImages.url}${editingSubcategory.subcategory_image}`}
      alt="Category Image"
      className="mt-2 rounded-lg object-cover w-24 h-24"
    />
  )}
</div>


            <div className="flex items-center">
               <Typography className="block font-bold">Active</Typography>
               <Switch
                 checked={isActive}
                 onChange={(e) => setIsActive(e.target.checked)}
                 style={{
                   color: isActive ? '#228B22' : '#D22B2B', // Thumb color
                 }}
                 // Optionally, you can change the track color as well
                 sx={{
                   '& .MuiSwitch-track': {
                     backgroundColor: isActive ? '#228B22' : '#D22B2B', // Track color
                   },
                   '& .MuiSwitch-thumb': {
                     backgroundColor: isActive ? '#228B22' : '#D22B2B', // Thumb color
                   },
                 }}
               />
             </div>
 
      {/* Footer Buttons */}
      <div className="modalFooter">
      <Button     variant="contained"
          onClick={() => setModalOpen(false)}
   className="bg-gray-500 text-black py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Cancel
     </Button>

              <Button
                variant="contained"
                onClick={handleAddOrUpdateSubcategory}
                className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                {editingSubcategory ? "Update" : "Add"} SubCategory
              </Button>
      </div>
    </div>
</Modal>

    </div>
  );
};

export default SubCategoryForm;
