// pages/global/Topbar.js
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { MdLogout } from "react-icons/md"; // Importing the logout icon
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  // State to manage the visibility of the suggestion text
  const [showLogoutText, setShowLogoutText] = useState(false);

  const handleLogoutClick = () => {
    sessionStorage.clear(); 
    navigate("/login");
  };

  return (
    <Box display="flex" 
    justifyContent="space-between" 
    alignItems="center" 
    bgcolor="#198680"
    padding="20px 20px"
    sx={{ 
      '@media screen and (max-width: 600px)': {
        flexDirection: 'column', 
        alignItems: 'flex-start',
        padding: '10px',
      },
    }}>
      {/* SEARCH BAR */}
      <Box display="flex" backgroundColor="white" border="1px solid #5D3FD3" borderRadius="23px" sx={{ width: '17%', '@media screen and (max-width: 600px)': {
      width: '100%', // Full width on mobile screens
      maxWidth: '400px', // Limit max width for larger mobile screens
      margin: '0 auto', // Center align the box
      padding: '0 10px', // Ensure proper spacing
    }, }}>
        <InputBase sx={{ ml: 2, flex: 1, color: '#5D3FD3', fontSize: '14px', padding: '8px 0', '@media screen and (max-width: 600px)': {
        fontSize: '12px', // Adjust font size on mobile
        padding: '6px 0', // Adjust padding for mobile view
      }, }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1, color: '#5D3FD3' , fontSize: '20px', '@media screen and (max-width: 600px)': {
        fontSize: '18px', // Adjust icon size for mobile
      }, }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex" position="relative">
        <IconButton onClick={colorMode.toggleColorMode} sx={{ color: 'white' }}>
          {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
        </IconButton>
        <IconButton sx={{ color: 'white' }}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton sx={{ color: 'white' }}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton 
          onClick={handleLogoutClick}
          onMouseEnter={() => setShowLogoutText(true)} // Show text on hover
          onMouseLeave={() => setShowLogoutText(false)} // Hide text on mouse leave
        >
          <MdLogout size={34} color="red" />
        </IconButton>
        {showLogoutText && (
          <Typography 
            variant="caption" 
            style={{
              position: 'absolute', 
              bottom: '-50%', // Positioning the text below the icon
              left: '50%', 
              transform: 'translate(-50%, 0)', // Centering the text horizontally
              color: theme.palette.mode === "dark" ? "white" : "black", // Dynamic text color based on theme
              padding: '2px 4px', 
              borderRadius: '4px',
              zIndex: 1,
              fontSize: '1rem',
              marginTop: '15px' // Increased font size
            }}
          >
            Log Out
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;