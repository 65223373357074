import React from 'react';
import { FaCircleNotch } from 'react-icons/fa'; // Import a different spinner icon from React Icons

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <FaCircleNotch className="animate-spin text-green-500 text-5xl mb-4" />
      <p className="text-gray-600 text-lg font-semibold">Loading, please wait...</p>
    </div>
  );
};

export default Loader;
