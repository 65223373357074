import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { MdCategory } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { TbPackage } from 'react-icons/tb';
import { BiSolidCategory } from "react-icons/bi";
import { BsFillBoxFill } from "react-icons/bs";
import { FaWarehouse } from "react-icons/fa";

// // import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { FaUser  } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';  // Import the calendar icon
import { FaUserTie } from 'react-icons/fa'; // For Service Provider
// import { FaUserCircle } from 'react-icons/fa'; // For Provider Details
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "#fff",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
    sx={{
      "& .pro-sidebar-inner": {
        background: `#198680 !important`,
        height: "140vh !important", // Set a fixed height (e.g., 400px)
        position: "sticky",
        right : 0,
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !important",
      },
      "& .pro-inner-item": {
        padding: "5px 35px 5px 20px !important",
      },
      "& .pro-inner-item:hover": {
        //color: "#868dfb !important",
        color: "#000 !important",
      },
      "& .pro-menu-item.active": {
        color: "#fff !important",
      },
    }}
    
    
    
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#fff",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color="#fff">
                  MECH GURU
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} sx={{ color: 'white' }}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="25px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="80px"
                height="80px"
                borderRadius="50%"
                bgcolor="#fff" // Background color for the icon container
              >
                <FaUser  size={40} color={colors.grey[100]} /> {/* Using the React icon */}
              </Box>
            </Box>
            <Box textAlign="center">
              <Typography
                variant="h5"
                color="#fff"
              >ADMIN
              </Typography>
            </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color="#fff"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Products
            </Typography>
            <Item
              title="Categories"
              to="/categorypage"
              icon={<MdCategory size={30} />} // Set the desired size here
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Sub Categories"
              to="/subcategorypage"
              icon={<BiSolidCategory size={30} />} // Set the desired size here
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Products"
              to="/productspage"
              icon={<BsFillBoxFill   size={30} />} // Set the desired size here
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title="Services"
                to="/servicespage"
                icon={<MdSettings size={30} />} // Use an icon that represents services
                selected={selected}
                setSelected={setSelected}
                />
            <Item
                  title="Packages"
                  to="/packagepage"
                  icon={<TbPackage size={30} />} // Package icon
                  selected={selected}
                  setSelected={setSelected}
                />
            {/* <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Typography
              variant="h6"
              color="#fff"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>

            <Item
              title="Service Provider"
              to="/service-providers"
              icon={<FaUserTie className="text-2xl" />}  // Increase icon size to 3xl
              selected={selected}
              setSelected={setSelected}
            />
          {/* <Item
            title={<span className="text-teal-400">Bookings</span>}  // Apply light teal color to the title text
            to="/booking-details"
            icon={<FaCalendarAlt className="text-3xl text-teal-400" />}  // Apply light teal color to the icon
            selected={selected}
            setSelected={setSelected}
          /> */}
<Item
  title="Distributors"
  to="/distributor-page"
  icon={<FaWarehouse style={{ fontSize: "2rem" }} />} // Adjusted size to 2rem (equivalent to 3xl)
  selected={selected}
  setSelected={setSelected}
/>

          <Item
            title={<span className="">Bookings</span>}  // Apply light teal color to the title text
            to="/booking-details"
            icon={<FaCalendarAlt className="text-3xl" />}  // Apply light teal color to the icon
            selected={selected}
            setSelected={setSelected}
          />

          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
