import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Get URL params
import axios from "axios"; // For making API calls
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { CircularProgress, Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from "@mui/material";
import API_URLS from "../summaryapi/apiUrls"; // Assuming API_URLS is the file you shared above

const ServiceProviderDetails = () => {
  const { service_provider_id } = useParams();
  const [providerDetails, setProviderDetails] = useState(null);
  const [bookings, setBookings] = useState([]); // State to store fetched bookings
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null); // For storing selected booking details to show in modal
  const [openModal, setOpenModal] = useState(false); // For controlling modal visibility

  const [page, setPage] = useState(0); // Pagination state for page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Pagination state for rows per page

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch Provider Details
  const fetchProviderDetails = async () => {
    try {
      const providerResponse = await axios.post(API_URLS.getProviderDetailsById.url,    
        JSON.stringify({ service_provider_id: service_provider_id }), 
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const providerData = providerResponse.data.data;
      if (providerData) {
        setProviderDetails(providerData);
      }
    } catch (err) {
      setError("Error fetching service provider details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Bookings Data
  const fetchProviderBookings = async () => {
    try {
      const bookingsResponse = await axios.post(API_URLS.getProviderBookingsById.url,    
        JSON.stringify({ service_provider_id: service_provider_id }), 
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const bookingsData = bookingsResponse.data.data;
      if (bookingsData) {
        setBookings(bookingsData);
      }
    } catch (err) {
      setError("Error fetching bookings data.");
    }
  };

  // Fetch data when the component is mounted
  useEffect(() => {
    fetchProviderDetails();
    fetchProviderBookings();
  }, [service_provider_id]);

  // Handle loading and error states
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Ensures it takes full screen height
          flexDirection: "column",
          backgroundColor: "rgba(0, 0, 0, 0.1)", // Light background for loading overlay
        }}
      >
        <CircularProgress sx={{ color: colors.blueAccent[500], marginBottom: 2 }} />
        <Typography sx={{ color: colors.primary[100], fontWeight: "bold" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!providerDetails) {
    return <div>No provider found!</div>;
  }

  // Handle the "View More" button click to show the modal with service details
  const handleViewClick = (booking) => {
    setSelectedBooking(booking); // Store the selected booking
    setOpenModal(true); // Open the modal
  };

  // Handle modal close
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBooking(null); // Reset the selected booking
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  // Slice bookings data based on pagination
  const paginatedBookings = bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
<Box m="20px" sx={{
  overflowY: "auto", 
  height: "calc(100vh - 80px)", 
  paddingBottom: "20px", 
  '&::-webkit-scrollbar': {
    display: 'none', 
  },
  scrollbarWidth: 'none', 
}}>
  {/* Page Title */}
  <Typography
    variant="h4"
    component="h1"
    sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 2 }}
  >
    <h1 className="font-poppins font-bold text-center text-[30px]">
      {providerDetails.business_name}
    </h1>
  </Typography>

  {/* Provider Info */}
  <Box
    mb={6}
    p={6}
    sx={{
      backgroundColor: colors.primary[400],
      borderRadius: "8px",
      boxShadow: 2,
      display: "flex",
      flexDirection: { xs: "column", sm: "row" }, // Make it responsive for mobile
      alignItems: "center",
      justifyContent: "space-between",
      gap: 2
    }}
  >
    {/* Business Image */}
    <Box sx={{ width: { xs: "100%", sm: "30%" }, textAlign: "center" }}>
      <img
        className="w-full h-auto object-cover mt-2"
        src={`${API_URLS.getproviderImages.url}/${providerDetails.business_image}`} 
        alt={`${providerDetails.business_name} Business`}
        style={{ borderRadius: "8px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
      />
    </Box>
    
    {/* Provider Details */}
 {/* Provider Details */}
<Box
  sx={{
    width: "100%",
    marginTop: { xs: 2, sm: 0 },
    display: "grid",
    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, // One column on small screens, two on larger
    gap: 4, // Equal gap between columns
    paddingLeft: { sm: 1 }, // Optional padding for left alignment on larger screens
    paddingRight: { sm: 1 },
  }}
>
  <Typography sx={{ color: colors.primary[100] }}>
    <strong>Business Name:</strong> {providerDetails.business_name}
  </Typography>
  <Typography sx={{ color: colors.primary[100] }}>
    <strong>Owner:</strong> {providerDetails.full_name}
  </Typography>
  <Typography sx={{ color: colors.primary[100] }}>
    <strong>About:</strong> {providerDetails.about_business}
  </Typography>
  <Typography sx={{ color: colors.primary[100] }}>
    <strong>Address:</strong> {providerDetails.address}, {providerDetails.city}
  </Typography>
</Box>


    {/* QR Code */}
    {providerDetails.qr_code && (
      <Box sx={{ textAlign: "center", marginTop: { xs: 2, sm: 0 } }}>
        <img
          className="w-60 h-50 object-cover mt-2"
          src={`${API_URLS.getproviderqrImages.url}${providerDetails.qr_code}`} 
          alt="QR Code"
          style={{ borderRadius: "8px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        />
      </Box>
    )}
  </Box>

  {/* Customer Bookings */}
  <Typography
    variant="h3"
    sx={{ color: colors.primary[100], fontWeight: "bold", textAlign: "center", mt: 8 }}
  >
    Customer Bookings
  </Typography>

  {/* Scrollable Table Container */}
  <Box
    sx={{
      maxHeight: bookings.length > 5 ? '400px' : 'auto',
      overflowY: bookings.length > 10 ? 'auto' : 'visible', 
      overflowY: "auto",
      borderRadius: "8px",
      boxShadow: 2,
      mt: 2,
      fontFamily: '"Source Sans Pro", sans-serif',
      fontWeight: "bold",
    }}
  >
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Booking Code</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Package / Service Name</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Package Price</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Booking Date</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Booking Mode</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Total Price</TableCell>
            <TableCell sx={{ backgroundColor: colors.blueAccent[500], color: "#fff" }}>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {paginatedBookings.map((booking) => (
            <TableRow key={booking.customer_booking_id}>
              <TableCell>{booking.booking_code}</TableCell>
              <TableCell>
                {booking.package_id > 0 ? (
                  <strong>
                    <span
                      style={{
                        backgroundColor: colors.greenAccent[700],
                        color: colors.primary[100],
                        padding: '2px 6px',
                        borderRadius: '4px',
                        marginRight: '5px',
                      }}
                    >
                      package
                    </span>
                    {booking.package_name} 
                  </strong>
                ) : (
                  <>
                    {booking.itemsData.map((item, idx) => (
                      <div key={idx}>
                        <strong>
                          <span
                            style={{
                              backgroundColor: colors.redAccent[700],
                              color: colors.primary[100],
                              padding: '2px 6px',
                              borderRadius: '4px',
                              marginRight: '5px',
                            }}
                          >
                            service
                          </span>   
                          {item.service_name}    
                        </strong>
                      </div>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell>
                {booking.package_id > 0 ? (
                  `$${booking.package_price}`
                ) : (
                  <>
                    {booking.itemsData.map((item, idx) => (
                      <div key={idx}>
                        <span>{`$${item.service_price}`}</span>
                      </div>
                    ))}
                  </>
                )}
              </TableCell>
              <TableCell>{new Date(booking.service_booked_date).toLocaleDateString()}</TableCell>
              <TableCell>{booking.booking_mode}</TableCell>
              <TableCell>{`₹${booking.total_payable_price}`}</TableCell>
              <TableCell>
                <Button
                  onClick={() => handleViewClick(booking)} 
                  sx={{
                    width: "90px",
                    textTransform: "none",
                    backgroundColor: colors.greenAccent[500],
                    color: "#fff",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[700],
                    },
                  }}
                >
                  View Services
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>

  {/* Pagination controls */}
  <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={bookings.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />

  {/* View Services Modal */}
  <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
    <DialogTitle className="text-xl font-semibold text-center" sx={{
      color: colors.primary[100],
    }}>
      Service Details
    </DialogTitle>
    <DialogContent className="p-6 space-y-4">
      <div className="grid grid-cols-2 gap-6">
        {selectedBooking?.itemsData?.map((item, idx) => (
          <Box
            key={idx}
            className="flex flex-col items-center space-y-4"
          >
            {/* Image */}
            {item.service_image2 && (
              <div className="w-full max-w-[180px]">
                <img
                  src={`${API_URLS.getServiceImages.url}${item.service_image2}`}
                  alt={item.service_name}
                  className="w-full h-auto rounded-lg shadow-md object-cover"
                />
              </div>
            )}

            {/* Service Details */}
            <div className="text-center">
              <Typography variant="body1" className="text-lg font-semibold" sx={{
                color: colors.primary[100],
              }} >
                <strong>{item.service_name}</strong>
              </Typography>
              <Typography variant="body2" sx={{
                color: colors.primary[100],
              }} >
                Price: ₹{item.service_price}
              </Typography>
            </div>
          </Box>
        ))}
      </div>
    </DialogContent>
    <DialogActions className="p-4">
      <Button
        onClick={handleCloseModal}
        color="primary"
        className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-red-600" sx={{
          backgroundColor: colors.primary[400],
          color: colors.primary[100],
        }} 
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
</Box>

  );
};

export default ServiceProviderDetails;
