import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import API_URLS from "../summaryapi/apiUrls";
import axios from "axios";

const ProductsModal = ({ product, onClose, refreshProducts }) => {
  const [productName, setProductName] = useState("");
  const [productSubtitle, setProductSubtitle] = useState("");
  const [mrp, setMrp] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [productImage1, setProductImage1] = useState(null);
  const [productImage2, setProductImage2] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubcategoryId] = useState("");
  const [distributorId, setDistributorId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [distributors, setDistributors] = useState([]);

  useEffect(() => {
    if (product) {
      setProductName(product.product_name || "");
      setProductSubtitle(product.product_subtitle || "");
      setMrp(product.mrp || "");
      setSellingPrice(product.selling_price || "");
      setDiscount(product.discount || "");
      setIsActive(product.is_active === "1");
      setCategoryId(product.category_id || "");
      setSubcategoryId(product.subcategory_id || "");
      setDistributorId(product.distributor_id || "");
      setProductCode(product.product_code || "");
      setHsnCode(product.hsn_code || "");
    }
    fetchCategories();
    fetchSubcategories();
    fetchDistributors();
  }, [product]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(API_URLS.getActiveCategorys.url);
      setCategories(response.data?.data || []);
    } catch {
      toast.error("Failed to fetch categories.");
    }
  };

  const fetchSubcategories = async () => {
    try {
      const response = await axios.get(API_URLS.getActiveSubcategory.url);
      setSubcategories(response.data?.data || []);
    } catch {
      toast.error("Failed to fetch subcategories.");
    }
  };

  const fetchDistributors = async () => {
    try {
      const response = await axios.get(API_URLS.getActiveDistributors.url);
      setDistributors(response.data?.data || []);
    } catch {
      toast.error("Failed to fetch distributors.");
    }
  };

  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("product_name", productName);
    formData.append("product_subtitle", productSubtitle);
    formData.append("mrp", mrp);
    formData.append("selling_price", sellingPrice);
    formData.append("discount", discount);
    formData.append("is_active", isActive ? "1" : "0");
    formData.append("category_id", categoryId);
    formData.append("subcategory_id", subcategoryId);
    formData.append("distributor_id", distributorId);
    formData.append("product_code", productCode);
    formData.append("hsn_code", hsnCode);
    if (productImage1) formData.append("product_image1", productImage1);
    if (productImage2) formData.append("product_image2", productImage2);

    const url = product
      ? API_URLS.updateProducts.url
      : API_URLS.createProducts.url;
    const method = product
      ? API_URLS.updateProducts.method
      : API_URLS.createProducts.method;

    try {
      const response = await axios({ url, method, data: formData });
      if (response.data.status) {
        toast.success(product ? "Product updated!" : "Product added!");
        refreshProducts();
        onClose();
      } else {
        toast.error("Failed to save product.");
      }
    } catch {
      toast.error("Error saving product.");
    }
  };

  return (
    <Modal open onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {product ? "Edit Product" : "Add Product"}
        </Typography>
        <TextField
          label="Product Name"
          fullWidth
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Product Subtitle"
          fullWidth
          value={productSubtitle}
          onChange={(e) => setProductSubtitle(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="MRP"
          fullWidth
          value={mrp}
          onChange={(e) => setMrp(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Selling Price"
          fullWidth
          value={sellingPrice}
          onChange={(e) => setSellingPrice(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Discount"
          fullWidth
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          sx={{ mt: 2 }}
        />
        <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default ProductsModal;
