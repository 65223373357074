import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import React from "react";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">

      <Box display="flex" justifyContent="space-between" alignItems="center">

        <Box display="flex" alignItems="center">
        {React.cloneElement(icon, { sx: { fontSize: "55px" , color: "white" } })}  
        </Box>

        <Box sx={{ height: '125px', width: '1px', backgroundColor: 'gray', marginLeft: '16px', marginRight: '16px' }} />

        <Box>
        <Typography variant="h3" sx={{ color: "white" }}>
            {subtitle}
          </Typography>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ color: "white" }} 
          >
            {title}
          </Typography>
          
        </Box>

        
      </Box>
    </Box>
  );
};

export default StatBox;
