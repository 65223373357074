import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from 'react-icons/fa'; // For the search icon
import { toast } from 'react-toastify'; // Import toast and container
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
const GOOGLE_API_KEY = 'AIzaSyBVA9j7aOagcojxaK-fmrG9fxJVi7kvgV0';

const MapPage = () => {
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
  
    // Decode the Base64-encoded parameters
    const addressEncoded = searchParams.get("address");
    const cityEncoded = searchParams.get("city");
    const providerIdEncoded = searchParams.get("provider_id");
  
    // Return the decoded parameters, without latitude and longitude
    return {
      address: addressEncoded ? decodeURIComponent(atob(addressEncoded)) : "",
      city: cityEncoded ? decodeURIComponent(atob(cityEncoded)) : "",
      provider_id: providerIdEncoded ? atob(providerIdEncoded) : "",
    };
  };

  const mapContainerRef = useRef(null);
  const googleMapRef = useRef(null); // Reference to the Google map instance
  const googleMarkerRef = useRef(null); // Reference to the marker instance
  const searchInputRef = useRef(null); // Reference to the search bar input
  const [markerPosition, setMarkerPosition] = useState({ lat: 15.9129, lng: 79.7400 });
  const [searchQuery, setSearchQuery] = useState(getQueryParams().address); // Populate from URL
  const [predictions, setPredictions] = useState([]);
  const [address, setAddress] = useState(getQueryParams().address); // Set the initial address from URL
  const [addressFinal, setAddressFinal] = useState(""); // State for final updated address
  const [city, setCity] = useState(""); // State to hold city information

  const loadGoogleMapsScript = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        script.onload = () => resolve(window.google);
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      }
    });
  };

  const initializeMap = (google) => {
    googleMapRef.current = new google.maps.Map(mapContainerRef.current, {
      center: markerPosition,
      zoom: 12,
    });

    googleMarkerRef.current = new google.maps.Marker({
      position: markerPosition,
      map: googleMapRef.current,
      draggable: true,
    });

    googleMarkerRef.current.addListener("dragend", (e) => {
      const newLat = e.latLng.lat();
      const newLng = e.latLng.lng();
      setMarkerPosition({ lat: newLat, lng: newLng });
      getAddressFromLatLng(newLat, newLng); // Update address when the marker is dragged
    });
  };

  const getAddressFromLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address); // Update the address
        setSearchQuery(results[0].formatted_address); // Update the search query with the address
        extractCity(results[0].address_components); // Extract and set city
  
        // Automatically update latitude and longitude when the marker is dragged
        setMarkerPosition({ lat, lng });
  
        console.log('Selected Latitude:', lat);  // Log latitude to the console
        console.log('Selected Longitude:', lng);  // Log longitude to the console
      }
    });
  };
  

  const extractCity = (addressComponents) => {
    const cityComponent = addressComponents.find((component) =>
      component.types.includes("locality") || component.types.includes("administrative_area_level_1")
    );
    if (cityComponent) {
      setCity(cityComponent.long_name); // Set the city in the state
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      // Fetch predictions if search query is not empty
      fetchPredictions(event.target.value);
    } else {
      setPredictions([]); // Clear predictions when input is empty
    }
  
    // Trigger geocoding when a valid address is entered
    if (event.target.value && event.key === "Enter") {
      handleSearchSubmit();
    }
  };
  
  const fetchPredictions = (query) => {
    const google = window.google;
    const service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input: query }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setPredictions(results); // Set the predictions from the service
      } else {
        setPredictions([]); // Clear predictions if status is not OK
      }
    });
  };

  const handleAddressChange = (newAddress) => {
    const providerId = new URLSearchParams(window.location.search).get("provider_id");
    const encodedProviderId = btoa(providerId);
    // Send the updated address, city, and provider_id to the parent window
    window.opener.postMessage(
      { address: newAddress, city: city, provider_id: encodedProviderId },
      "*"  // Send message to any origin or restrict it to your app's origin
    );
  };
  const handlePredictionClick = (prediction) => {
    const google = window.google;
    const geocoder = new google.maps.Geocoder();
  
    // Geocode the selected prediction to get the coordinates
    geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        const newPosition = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMarkerPosition(newPosition); // Update marker position
        googleMapRef.current.setCenter(newPosition); // Update map center
        setSearchQuery(""); // Clear the search input after selection
        setPredictions([]); // Clear the suggestions
  
        // Set the address
        setAddress(results[0].formatted_address);
        extractCity(results[0].address_components); // Extract and set city
  
        console.log('Selected Latitude:', newPosition.lat);  // Log latitude to the console
        console.log('Selected Longitude:', newPosition.lng);  // Log longitude to the console
  
        // Send updated coordinates to parent window
        updateParentWindowWithLatLng(newPosition);
      }
    });
  };

  const updateParentWindowWithLatLng = (newPosition) => {
    const providerId = new URLSearchParams(window.location.search).get("provider_id");
    const encodedProviderId = btoa(providerId);
    // Send the updated latitude and longitude along with address and city to the parent window
    window.opener.postMessage(
      {
        address: address,
        city: city,
        lat: newPosition.lat,    // Pass the latitude
        lng: newPosition.lng,    // Pass the longitude
        provider_id: encodedProviderId,
      },
      "*"  // Send message to any origin or restrict it to your app's origin
    );
  };

  const handleSearchSubmit = () => {
    if (!searchQuery) return;
  
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        const newPosition = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMarkerPosition(newPosition); // Update marker position
        googleMapRef.current.setCenter(newPosition); // Update map center
        setAddress(results[0].formatted_address); // Set the address
        extractCity(results[0].address_components); // Extract and set city
  
        // Automatically set Latitude and Longitude
        setMarkerPosition({
          lat: location.lat(),
          lng: location.lng(),
        });
  
        // Send updated coordinates to parent window
        updateParentWindowWithLatLng(newPosition);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };
  
  const handleUpdateAddress = () => {
    if (address) {
      const confirmUpdate = window.confirm(
        `Are you sure you want to update this address? : ${address}`
      );
      if (confirmUpdate) {
        setAddressFinal(address);
        toast.success(`Address updated: ${address}`, {
          onClose: () => {
            setTimeout(() => {
              window.close();
            }, 20);
          },
        });
  
        const providerId = getQueryParams().provider_id; // Get decoded provider_id
        const encodedProviderId = btoa(providerId); // Encode if necessary before sending
  
        // Send the updated address, city, latitude, longitude, and provider_id to the parent window
        window.opener.postMessage(
          {
            address: address,
            city: city,
            lat: markerPosition.lat,    // Pass the latitude
            lng: markerPosition.lng,    // Pass the longitude
            provider_id: encodedProviderId, // Pass encoded provider_id
          },
          "*"  // Send message to any origin or restrict it to your app's origin
        );
  
        setAddress(address);
      }
    }
  };
  

  useEffect(() => {
    loadGoogleMapsScript()
      .then((google) => {
        initializeMap(google);
      })
      .catch((error) => console.error("Error loading Google Maps script:", error));
  }, []); // Only run once when the component mounts

  useEffect(() => {
    if (googleMarkerRef.current) {
      googleMarkerRef.current.setPosition(markerPosition);
      if (googleMapRef.current) {
        googleMapRef.current.setCenter(markerPosition);
      }
    }
  }, [markerPosition]);

  return (
    <div className="flex justify-center p-5 mt-12">
      {/* Wrapper Box */}
      <div className="w-full max-w-[1000px] border border-[#e0e0e0] rounded-lg shadow-md p-8 bg-white">
        {/* Search Bar */}
        <div className="mb-5 w-full relative">
          <div className="flex w-full">
            {/* Search Bar */}
            <div className="relative w-full sm:w-7/10">
              <input
                ref={searchInputRef}
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={address ? "Enter address" : "Search for a location"} // Change placeholder dynamically
                className="w-full py-2 px-8 text-lg rounded-md border border-[#ccc] h-10"
              />
              {/* Search Icon inside input, vertically centered */}
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <FaSearch className="text-lg text-[#ccc]" />
              </div>
            </div>

            {/* Update Address Button */}
            <div className="w-full sm:w-3/10 ml-2">
              {/* Button takes the remaining 30% */}
              <div className="text-center mt-0">
                <button
                  onClick={handleUpdateAddress} // Update address when clicked
                  disabled={!address} // Disable if address is empty
                  className={`w-full py-2 px-5 text-lg rounded-md font-medium h-10 ${address ? 'bg-[#4CAF50] text-white cursor-pointer' : 'bg-[#d3d3d3] cursor-not-allowed'}`}
                >
                  Update Address
                </button>
              </div>
            </div>
          </div>

          {/* Suggestions Dropdown */}
          {predictions.length > 0 && (
            <div className="w-full max-h-[200px] overflow-y-auto border border-[#ccc] rounded-md shadow-md absolute top-full z-10 bg-white">
              {predictions.map((prediction, index) => (
                <div
                  key={index}
                  onClick={() => handlePredictionClick(prediction)}
                  className="py-2 cursor-pointer border-b border-[#ddd]"
                >
                  {prediction.description}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Map Container */}
        <div
          ref={mapContainerRef}
          className="w-full h-[60vh] rounded-lg shadow-md mt-12"
        ></div>

        {/* Address Display */}
        {address && (
          <div className="mt-5 text-center text-red-500 font-semibold text-2xl">
            <p>{address}</p>
          </div>
        )}

        {/* Latitude and Longitude Display */}
{/* Latitude and Longitude Display */}
<div className="mt-5 text-center text-green-500 font-semibold text-2xl" style={{ display: "none" }}>
  <p>Latitude: {markerPosition.lat.toFixed(6)}</p>
  <p>Longitude: {markerPosition.lng.toFixed(6)}</p>
</div>


        {/* City Display */}
        {city && (
          <div className="mt-5 text-center text-blue-500 font-semibold text-2xl">
            <p>{city}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapPage;
