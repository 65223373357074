import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { TextField, Button, Switch, FormControlLabel, InputAdornment, IconButton } from "@mui/material";
import API_URLS from "../summaryapi/apiUrls";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Eye icons for toggle
Modal.setAppElement("#root");

const DistributorModal = ({ open, onClose, distributor, fetchDistributors }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);// Get colors based on current theme mode
  const [imagePreviews, setImagePreviews] = useState({
    image1: null,
    image2: null,
  });
  const [error, setError] = useState({
    mobile_number1: "",
    mobile_number2: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false); // Manage submission state
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [formData, setFormData] = useState({
    full_name: "",
    organization_name: "",
    mobile_number1: "",
    mobile_number2: "",
    email: "",
    address: "",
    city: "",
    pincode: "",
    about: "",
    distributor_license: "",
    password: "", // Added password field
    latitude: "", // Default latitude
    longitude: "", // Default longitude
    is_active: true,
    image1: null,
    image2: null,
  });

  useEffect(() => {
    if (distributor) {
      const fetchDistributorDetails = async () => {
        try {
          const response = await axios.post(API_URLS.getDistributorById.url, {
            distributor_id: distributor.distributor_id,
          });

          if (response.data.status) {
            const data = response.data.data;

            setFormData({
              distributor_id: distributor.distributor_id,
              full_name: data.full_name || "",
              organization_name: data.organization_name || "",
              mobile_number1: data.mobile_number1 || "",
              mobile_number2: data.mobile_number2 || "",
              email: data.email || "",
              address: data.address || "",
              city: data.city || "",
              pincode: data.pincode || "",
              about: data.about || "",
              image1: null,
              image2: null,
              distributor_license: data.distributor_license || "",
              password: data.password || "",
              latitude: data.latitude,
              longitude: data.longitude,
              is_active: data.is_active === "1",
            });

            setImagePreviews({
              image1: `${API_URLS.getdistributorImages.url}${data.image1}` || "",
              image2: `${API_URLS.getdistributorImages.url}${data.image2}` || "",
            });
          } else {
            toast.error("Failed to fetch distributor details.");
          }
        } catch (error) {
          toast.error("Error fetching distributor details.");
        }
      };

      fetchDistributorDetails();
    } else if(open && !distributor){
      setFormData({
        full_name: "",
        organization_name: "",
        mobile_number1: "",
        mobile_number2: "",
        email: "",
        address: "",
        city: "",
        pincode: "",
        about: "",
        image1: null,
        image2: null,
        distributor_license: "",
        password: "",
        latitude: "",
        longitude: "",
        is_active: true,
      });

      setImagePreviews({ image1: "", image2: "" });
    }
  }, [open, distributor]);

  const validateMobileNumber = (number) => {
    const regex = /^[0-9]{10}$/; // Only numbers and exactly 10 digits
    return regex.test(number);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
    if (name === 'mobile_number1' || name === 'mobile_number2') {
      const isValid = validateMobileNumber(value);
      setError({ ...error, [name]: isValid ? '' : 'Invalid mobile number' });
    }
  };

  
  const handleToggleChange = (e) => {
    if (e.target && e.target.checked !== undefined) {
      const isActive = e.target.checked;
      setFormData((prevState) => ({
        ...prevState,
        is_active: isActive,
      }));
    }
  };

  const handleImageChange = (e, imageField) => {
    const { files } = e.target;
    if (files && files[0]) {
      setFormData({ ...formData, [imageField]: files[0] });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews({
          ...imagePreviews,
          [imageField]: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
    

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    if (formData.distributor_id) {
      formDataToSend.append("distributor_id", formData.distributor_id);
    }

    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("organization_name", formData.organization_name);
    formDataToSend.append("mobile_number1", formData.mobile_number1);
    formDataToSend.append("mobile_number2", formData.mobile_number2);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("pincode", formData.pincode);
    formDataToSend.append("about", formData.about);
    formDataToSend.append("distributor_license", formData.distributor_license);

    if (formData.password && formData.password !== "******") {
      formDataToSend.append("password", formData.password);
    }
    if (formData.image1) {
      formDataToSend.append("distributor_image1", formData.image1);
    }
    if (formData.image2) {
      formDataToSend.append("distributor_image2", formData.image2);
    }
    formDataToSend.append("latitude", formData.latitude);
    formDataToSend.append("longitude", formData.longitude);
    if (formData.is_active !== undefined) {
      formDataToSend.append("is_active", formData.is_active ? "1" : "0");
    }
  
    try {
      let response;
      if (distributor) {
        response = await axios.post(API_URLS.updateDistributor.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        response = await axios.post(API_URLS.createDistributor.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      if (response.data.status) {
        toast.success(distributor ? "Distributor updated successfully!" : "Distributor added successfully!");
        fetchDistributors();
        onClose();
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      toast.error("Error submitting data.");
    } finally {
      setIsSubmitting(false);
    }
  };



  return (
<Modal
  isOpen={open}
  onRequestClose={onClose}
  contentLabel={distributor ? "Edit Distributor" : "Add Distributor"}
  className="bg-white p-4 rounded-lg shadow-lg w-full sm:w-[90vw] md:w-[60vw] lg:w-[40vw] mx-auto relative"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
>

    {/* Close button */}
    <button
  onClick={onClose}
  className="absolute top-2 right-2 text-3xl text-gray-600 hover:text-gray-900 focus:outline-none"
>
  &times; {/* HTML entity for a cross (×) */}
</button>

  <h2 className="text-2xl mb-4 text-center font-bold text-gray-900">
    {distributor ? "Edit Distributor" : "Add Distributor"}
  </h2>

  <form
    onSubmit={handleSubmit}
    className="space-y-4 max-h-[80vh] overflow-y-auto pr-2"
  >
    <div className="flex flex-col">
      <label className="text-sm font-bold text-gray-500 mb-2">Full Name</label>
      <TextField
        name="full_name"
        value={formData.full_name}
        onChange={handleInputChange}
        fullWidth
        required
        placeholder="Enter Full Name"
        sx={{
          backgroundColor: colors.primary[400],
          color: colors.primary[100],
          '& .MuiInputBase-input': {
            height: '1.5rem',
            padding: '4px 8px',
          },
        }}
      />
    </div>

    <div className="flex flex-row justify-between gap-4">
      <div className="flex flex-col w-1/2">
        <label className="text-sm font-bold text-gray-500 mb-2">Mobile Number</label>
        <TextField
          name="mobile_number1"
          value={formData.mobile_number1}
          onChange={handleInputChange}
          fullWidth
          required  disabled={!!distributor}
          placeholder="Enter Mobile Number"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
          inputProps={{ maxLength: 10 }}
          error={!!error.mobile_number1}
          helperText={error.mobile_number1}
        />
      </div>

      <div className="flex flex-col w-1/2">
        <label className="text-sm font-bold text-gray-500 mb-2">Alternate Mobile Number</label>
        <TextField
          name="mobile_number2"
          value={formData.mobile_number2}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Alternate Mobile Number"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
          inputProps={{ maxLength: 10 }}
          error={!!error.mobile_number2}
          helperText={error.mobile_number2}
        />
      </div>
    </div>

    <div className="flex flex-row justify-between gap-4">
      <div className="flex flex-col w-full">
        <label className="text-sm font-bold text-gray-500 mb-2">Email</label>
        <TextField
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Email"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>

      <div className="flex flex-col w-full">
        <label className="text-sm font-bold text-gray-500 mb-2">Organization Name</label>
        <TextField
          name="organization_name"
          value={formData.organization_name}
          onChange={handleInputChange}
          fullWidth
          required
          placeholder="Enter Organization Name"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>
    </div>

    <div className="flex flex-row justify-between gap-4">
      <div className="flex-1 mr-2">
        <label className="text-sm font-bold text-gray-500 mb-2">Address</label>
        <TextField
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Address"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>
    </div>

    <div className="flex flex-row justify-between gap-4">
      <div className="flex flex-col w-1/2">
        <label className="text-sm font-bold text-gray-500 mb-2">City</label>
        <TextField
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          fullWidth
          required
          placeholder="Enter City"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.25rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>

      <div className="flex flex-col w-1/2">
        <label className="text-sm font-bold text-gray-500 mb-2">Pincode</label>
        <TextField
          name="pincode"
          value={formData.pincode}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Pincode"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.25rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>
    </div>

    <div className="flex flex-col">
      <label className="text-sm font-bold text-gray-500 mb-2">About</label>
      <TextField
        name="about"
        value={formData.about}
        onChange={handleInputChange}
        fullWidth
        placeholder="Enter About"
        sx={{
          backgroundColor: colors.primary[400],
          color: colors.primary[100],
          '& .MuiInputBase-input': {
            height: '1.5rem',
            padding: '4px 8px',
          },
        }}
      />
    </div>

    <div className="flex flex-row justify-between gap-4">
      <div className="flex flex-col w-full">
        <label className="text-sm font-bold text-gray-500 mb-2">Distributor License</label>
        <TextField
          name="distributor_license"
          value={formData.distributor_license}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Distributor License"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>

      <div className="flex flex-col w-full">
        <label className="text-sm font-bold text-gray-500 mb-2">Password</label>
        <TextField
          name="password"
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>
    </div>

    <div className="hidden">
      <div className="flex flex-col">
        <label className="text-sm font-bold text-gray-500 mb-2">Latitude</label>
        <TextField
          name="latitude"
          value={formData.latitude}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Latitude"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>

      <div className="flex flex-col">
        <label className="text-sm font-bold text-gray-500 mb-2">Longitude</label>
        <TextField
          name="longitude"
          value={formData.longitude}
          onChange={handleInputChange}
          fullWidth
          placeholder="Enter Longitude"
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
            '& .MuiInputBase-input': {
              height: '1.5rem',
              padding: '4px 8px',
            },
          }}
        />
      </div>
    </div>

    <div className="mb-4 flex space-x-4">
      <div className="w-1/2">
        <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Distributor Image 1</label>
        <input
          type="file"
          name="image1"
          onChange={(e) => handleImageChange(e, 'image1')}
          className="w-full px-3 py-2 border rounded"
          style={{
            borderColor: colors.primary[300],
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
          }}
        />
        {imagePreviews.image1 && (
          <div className="mt-2">
            <img
              src={imagePreviews.image1}
              alt="Distributor Image 1 Preview"
              className="w-24 h-24 object-cover rounded"
              style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
            />
          </div>
        )}
      </div>

      <div className="w-1/2">
        <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Distributor Image 2</label>
        <input
          type="file"
          name="image2"
          onChange={(e) => handleImageChange(e, 'image2')}
          className="w-full px-3 py-2 border rounded"
          style={{
            borderColor: colors.primary[300],
            backgroundColor: colors.primary[400],
            color: colors.primary[100],
          }}
        />
        {imagePreviews.image2 && (
          <div className="mt-2">
            <img
              src={imagePreviews.image2}
              alt="Distributor Image 2 Preview"
              className="w-24 h-24 object-cover rounded"
              style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
            />
          </div>
        )}
      </div>
    </div>

    <div className="flex items-center">
      <FormControlLabel
        control={<Switch checked={formData.is_active} onChange={handleToggleChange} />}
        label="Active"
      />
    </div>

    <div className="flex justify-center items-center gap-4">
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
        sx={{
          backgroundColor: colors.primary[500],
          "&:hover": { backgroundColor: colors.primary[600] },
        }}
      >
        {distributor ? "Update Distributor" : "Add Distributor"}
      </Button>
    </div>
  </form>
</Modal>

); };

export default DistributorModal;

