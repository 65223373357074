import React, { useState, useEffect } from "react";
import { Typography, Button, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { MdAddCircle } from "react-icons/md";
import API_URLS from "../../summaryapi/apiUrls";
import axios from "axios";
import ProductsModal from "../../components/ProductsModal"; // Import the modal component
import "./Products.css";

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [editProduct, setEditProduct] = useState(null); // State for the product to edit

  const fetchProducts = async () => {
    try {
      const response = await axios.get(API_URLS.getAllProducts.url);
      if (response.data && response.data.data) {
        setProducts(response.data.data);
      } else {
        toast.error("Failed to retrieve products.");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Error fetching products.");
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleAddProduct = () => {
    setEditProduct(null); // Clear any product being edited
    setIsModalOpen(true); // Open the modal
  };

  const handleEditProduct = (product) => {
    setEditProduct(product); // Set the product to edit
    setIsModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="w-90">
      <div
        className="font-poppins font-bold text-center text-[30px] mt-5 bg-gradient-to-r from-[#5F3FD3] via-[#2A1E63] to-[#000000] text-transparent bg-clip-text"
        style={{
          color: theme.palette.mode === "dark" ? "white" : "transparent",
        }}
      >
        Products
      </div>

      <div className="flex justify-end mb-[16px] w-[95%]">
        <Button
          variant="contained"
          color="success"
          size="large"
          className="w-40 text-white font-semibold pr-4 font-poppins"
          onClick={handleAddProduct} // Open modal for adding a new product
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            fontWeight: "bold",
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "#5D3FD3",
            color: "#5D3FD3",
            "&:hover": {
              background:
                "linear-gradient(45deg, #1E1350, #3C2A94, #5D3FD3, #7C62E2, #B89EFF)",
              fontWeight: "bold",
              borderColor: "#5D3FD3",
              color: "white",
            },
            "&:active": {
              background:
                "linear-gradient(45deg, #3C2A94, rgb(99, 74, 197), rgb(153, 133, 207))",
              color: "#5D3FD3",
              borderColor: "#5D3FD3",
            },
          }}
        >
          <MdAddCircle style={{ marginRight: "8px", fontSize: "20px" }} />
          Add Product
        </Button>
      </div>

      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.product_code}>
            <Card onClick={() => handleEditProduct(product)} style={{ cursor: "pointer" }}> {/* Open modal for editing */}
              <CardMedia
                component="img"
                height="200"
                image={
                  product.product_image1
                    ? `${API_URLS.getProductsImages.url}/${product.product_image1}`
                    : ""
                }
                alt={product.product_name}
              />
              <CardContent>
                <Typography variant="h6" className="font-poppins">
                  {product.product_name}
                </Typography>
                <Typography variant="body2" color="textSecondary" className="font-poppins">
                  {product.product_subtitle}
                </Typography>
                <Typography variant="body1" className="font-poppins">
                  MRP: ₹{product.mrp}
                </Typography>
                <Typography variant="body1" className="font-poppins">
                  Selling Price: ₹{product.selling_price}
                </Typography>
                <Typography variant="body1" className="font-poppins">
                  Discount: {product.discount}%
                </Typography>
                <Typography variant="body1" className="font-poppins">
                  Available: {product.is_available === "1" ? "Yes" : "No"}
                </Typography>
                <Typography variant="body1" className="font-poppins">
                  Active: {product.is_active === "1" ? "Yes" : "No"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {isModalOpen && (
        <ProductsModal
          product={editProduct} // Pass the product to edit or null for a new product
          onClose={handleModalClose} // Close modal handler
          refreshProducts={fetchProducts} // Refresh the product list after changes
        />
      )}
    </div>
  );
};

export default Products;
