import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Invoices from "./pages/invoices";
import Form from "./pages/form";
import Line from "./pages/line";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import Calendar from "./pages/calendar/calendar";
import Login from "./pages/Login/Login";
import CategoryForm from "./pages/categories/CategoryForm"; 
import Packages from "./pages/Packages"; 
import Loader from './components/Loader'; 
import ServiceForm from "./pages/Services"; 
import ServiceProvider from "./pages/ServiceProvider"; 
import ServiceProviderDetails from "./pages/ServiceProviderDetails"; 
import DistributorDetails from "./pages/DistributorDetails"; 
import BookingReport from "./pages/BookingDetails";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import "react-toastify/dist/ReactToastify.css"; 
import { ToastContainer } from 'react-toastify'; 
import AssignBooking from './pages/assignBooking';  
import MapPage from './pages/MapPage';
import MapPageForDistributor from './pages/MapPageForDistributor';  
import CustomersPage from './pages/CustomersPage'; 
import SubCategoryForm from './pages/subcategories/SubCategoryForm';  
import Products from './pages/products/Products';
import Distributor from './pages/Distributor';
function App() {
  const [loading, setLoading] = useState(false);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate(); // For programmatic navigation
  useEffect(() => {
    const encodedAdminId = sessionStorage.getItem("admin_id");
    const adminId = encodedAdminId ? atob(encodedAdminId) : null;
    if (!adminId && location.pathname !== "/login") {
      navigate("/login");
    }
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 500); // Adjust the delay as needed
    return () => clearTimeout(timer);
  }, [location.pathname, navigate]);
  
  const isLoginPage = location.pathname === "/login";
  const isMapPage = location.pathname === "/Map-page";
  const isDistributorMapPage = location.pathname === "/Map-page-distributor";
  const isAssignBookingPage = location.pathname === "/assign-booking"; // Added condition for assign-booking page

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading && <Loader />} 
        <div className={`app ${loading ? 'hidden' : ''}`}> 
          {/* Only show Sidebar and Topbar if not on Login, Map, or AssignBooking page */}
          {!isLoginPage && !isMapPage && !isDistributorMapPage && <Sidebar isSidebar={isSidebar}/>}
          <main className="content">
            {/* Only show Topbar if not on Login, Map, or AssignBooking page */}
            {!isLoginPage && !isMapPage && !isDistributorMapPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/categorypage" element={<CategoryForm />} />
              <Route path="/subcategorypage" element={<SubCategoryForm />} />
              <Route path="/productspage" element={<Products />} />
              <Route path="/servicespage" element={<ServiceForm />} />
              <Route path="/packagepage" element={<Packages />} />
              <Route path="/service-providers" element={<ServiceProvider />} />
              <Route path="/service-provider/:service_provider_id" element={<ServiceProviderDetails />} />
              <Route path="/distributor/:service_provider_id" element={<DistributorDetails />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/login" element={<Login />} />
              <Route path="/booking-details" element={<BookingReport />} />
              <Route path="/assign-booking" element={<AssignBooking />} />
              <Route path="/customer-details" element={<CustomersPage />} />
              <Route path="/Map-page" element={<MapPage />} /> 
              <Route path="/Map-page-distributor" element={<MapPageForDistributor />} />
              <Route path="/distributor-page" element={<Distributor />} />
            </Routes>
          </main>
        </div>
        <ToastContainer
          className="!z-[9999]"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );

}

export default App;
