import React, { useState } from "react";
import { Link } from "react-router-dom";
import API_URLS from "../summaryapi/apiUrls"; // Assuming API URLs are stored in a separate file
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Importing icons for password visibility toggle
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
const ServiceProvider = ({
  service_provider_id,
  full_name,
  mobile_number,
  alternate_mobile_number,
  business_name,
  city,
  address,
  profile_image,
  business_image,
  about_business,
  is_active,
  is_available,
  password,
  vehicle_type,
  latitude,
  longitude
}) => {
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const statusText = is_active === "1" ? "Active" : "Inactive";
  const statusClass = is_active === "1" ? "text-green-500" : "text-red-500";
  const profileImageUrl = `${API_URLS.getproviderImages.url}${profile_image}`;
  const businessImageUrl = `${API_URLS.getproviderImages.url}${business_image}`;
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle the password visibility state
  };
  return (
    <div className="scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-600">
      <Link
        to={`/service-provider/${service_provider_id}`}
        className="max-w-xs rounded overflow-hidden bg-white"
      >
        {/* Business Image */}
        <div className="relative flex justify-center items-center mt-8">
          <img
            className="w-48 h-25 object-cover"
            src={businessImageUrl}
            alt={`${business_name} Business`}
          />
          {/* Profile Image (overlay at the top of the business image) */}
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-10">
            <img
              className="w-16 h-16 object-cover rounded-full border-4 border-white"
              src={profileImageUrl}
              alt={`${full_name} Profile`}
            />
          </div>
                  <div className="absolute top-2 right-2">
                      {is_active === "1" ? (
                        <FiCheckCircle className="text-green-500 w-6 h-6" /> // Checkmark for active
                      ) : (
                        <FiXCircle className="text-red-500 w-6 h-6" /> // Crossmark for inactive
                      )}
                    </div>
        </div>
  
        {/* Content Section */}
        <div className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              <strong>{full_name}</strong>
            </h2>
            <span className={`text-sm font-medium ${statusClass}`}>({statusText})</span>
          </div>
          <div style={{ display: 'none' }} className="flex justify-between">
            <p className="text-gray-500 text-sm"><strong>service provider id</strong></p>
            <p className="text-gray-500 text-sm">{service_provider_id}</p>
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Business:</strong></p>
              <p className="text-gray-500 text-sm">{business_name}</p>
            </div>
  
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>About Business:</strong></p>
              <p className="text-gray-500 text-sm">{about_business}</p>
            </div>
          </div>
  
          <div className="mt-4 space-y-2">
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Mobile:</strong></p>
              <p className="text-gray-500 text-sm">{mobile_number}</p>
            </div>
            {alternate_mobile_number && (
              <div className="flex justify-between">
                <p className="text-gray-500 text-sm"><strong>Alternate Mobile:</strong></p>
                <p className="text-gray-500 text-sm">{alternate_mobile_number}</p>
              </div>
            )}
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>City:</strong></p>
              <p className="text-gray-500 text-sm">{city}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Address:</strong></p>
              <p className="text-gray-500 text-sm truncate">{address}</p>
            </div>
  
            <div style={{ display: 'none' }}>
              <div className="flex justify-between">
                <p className="text-gray-500 text-sm"><strong>Latitude:</strong></p>
                <p className="text-gray-500 text-sm">{longitude}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-500 text-sm"><strong>Longitude:</strong></p>
                <p className="text-gray-500 text-sm">{latitude}</p>
              </div>
            </div>
  
            <div className="flex justify-between">
              <p className="text-gray-500 text-sm"><strong>Vehicle Type:</strong></p>
              <p className="text-gray-500 text-sm">{vehicle_type}</p>
            </div>
  
            <div className="mt-4">
              <div className="flex justify-between">
                <p className="text-sm font-medium">
                  <strong>Is Available:</strong>
                </p>
                <p className={`text-sm font-medium ${is_available === "1" ? "text-green-500" : "text-red-500"}`}>
                  {is_available === "1" ? "Available" : "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
  
      {/* Password section */}
      <div className="flex flex-row items-center justify-between mr-3 ml-3">
        <div className="flex items-center space-x-2">
          <p className="text-gray-500 text-sm">
            <strong>Password:</strong> {showPassword ? password : "******"}
          </p>
        </div>
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="text-sm text-blue-500 hover:text-blue-700"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </button>
      </div>
    </div>
  );
  
  
};

export default ServiceProvider;
