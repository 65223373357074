import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import API_URLS from "../summaryapi/apiUrls"; // Make sure to import API_URLS for images
import { Card, CardContent, CardMedia } from '@mui/material';

const BookingReport = ({ bookingsData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {};
  const blueAccent700 = colors.blueAccent?.[700] || "#0d47a1";
  const [openModal, setOpenModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const [hasBookings, setHasBookings] = useState(true); // State to track if bookings exist

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBooking(null);
  };

  const handleViewClick = (booking) => {
    setSelectedBooking(booking);
    setOpenModal(true);
  };

  // Simulate a 30-second loading time
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Stop loading after 30 seconds
      if (bookingsData.length === 0) {
        setHasBookings(false); // If no bookings, set to false
      }
    }, 3000); // 30 seconds

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [bookingsData]);

  // Define columns for the DataGrid
  const columns = [
    {
      field: "booking_code",
      headerName: "Booking Code",
      flex: 0.2,
    },
    {
      field: "package_name",
      headerName: "Package Name",
      flex: 0.3,
      renderCell: (params) => {
        const booking = params.row;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {booking.package_id > 0 ? (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <span
                  style={{
                    backgroundColor: '#FF7F50',
                    color: 'white',
                    padding: "2px 6px",
                    borderRadius: "4px",
                    marginRight: "10px", // Space between label and name
                    width: '80px',  // Fixed width for the label to align text
                    textAlign: 'center', // Center the label text
                    fontSize: '0.875rem',
                    display: 'inline-block',  // Prevent text overflow and ensure alignment
                  }}
                >
                  Package
                </span>
                <span style={{ display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {booking.package_name}
                </span>
              </div>
            ) : (
              booking.itemsData.map((item, idx) => (
                <div key={idx} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <span
                    style={{
                      backgroundColor: '#FF7F50',
                      color: 'white',
                      padding: "2px 6px",
                      borderRadius: "4px",
                      marginRight: "10px", // Space between label and name
                      width: '80px',  // Fixed width for the label to align text
                      textAlign: 'center', // Center the label text
                      fontSize: '0.875rem',
                      display: 'inline-block',  // Prevent text overflow and ensure alignment
                    }}
                  >
                    Service
                  </span>
                  <span style={{ display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {item.service_name}
                  </span>
                </div>
              ))
            )}
          </div>
        );
      },
    },
    {
      field: "booking_price",
      headerName: "Booking Price",
      flex: 0.2,
      renderCell: (params) => {
        const booking = params.row;
        return `₹${booking.booking_price}`;
      },
    },
    {
      field: "total_payable_price",
      headerName: "Total Payable Price",
      flex: 0.3,
      renderCell: (params) => {
        const booking = params.row;
        return `₹${booking.total_payable_price}`;
      },
    },
    {
      field: "full_name",
      headerName: "Customer Name",
      flex: 0.3,
    },
    {
      field: "mobile_number",
      headerName: "Customer Mobile Number",
      flex: 0.3,
    },
    {
      field: "booking_mode",
      headerName: "Booking Mode",
      flex: 0.3,
    },
    {
      field: "service_booked_date",
      headerName: "Service Date",
      flex: 0.3,
      renderCell: (params) => {
        const date = new Date(params.row.service_booked_date);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');  // months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      headerClassName: "header-cell",  // Apply header styling
    },
    {
      field: "view_services",
      headerName: "View Services",
      renderCell: (params) => (
        <Button
          onClick={() => handleViewClick(params.row)}
          sx={{
            width: "120px",
            textTransform: "none",
            backgroundColor: '#FF7F50',
            color: "#fff",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
        >
          View Services
        </Button>
      ),
      flex: 0.3,
    },
  ];

  // Map the booking data to rows for the DataGrid
  const rows = bookingsData.length > 0 
  ? bookingsData
      .map((booking) => ({
        id: booking.customer_booking_id,
        booking_code: booking.booking_code,
        package_name: booking.package_name || booking.service_name,
        full_name: booking.full_name,
        mobile_number: booking.mobile_number,
        booking_price: booking.booking_price,
        total_payable_price: booking.total_payable_price,
        booking_mode: booking.booking_mode || "N/A",
        service_booked_date: new Date(booking.service_booked_date).toLocaleDateString(),
        itemsData: booking.itemsData,
        package_id: booking.package_id,
      }))
      .sort((a, b) => new Date(b.service_booked_date) - new Date(a.service_booked_date)) // Sort in descending order by service_booked_date
  : []; // Empty array if no bookings

  return (
    <Box m="30px 0 0 0" sx={{ marginTop: 30 }}>
      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: colors.primary[100], marginBottom: '16px', textAlign: 'center' }}>
        Today Bookings
      </Typography>

      <Box
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[500],
            borderBottom: "none",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
            fontSize: "16px",
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary?.[400] || "#b3e5fc",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
                      backgroundColor: colors.blueAccent[500],
                  color: colors.primary[400],
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
            loading={isLoading} // Show loading spinner
          />
        </div>
      </Box>

      {/* Modal for Service Details */}
      <Dialog open={openModal} onClose={handleCloseModal}  sx={{ "& .MuiDialog-paper": { width: "90%" } }}>
        <DialogTitle className="text-xl font-semibold text-center" sx={{ color: colors.primary[100] }}>
          Service Details
        </DialogTitle>
        <DialogContent className="p-3 space-y-2">
  <div className="grid grid-cols-2 gap-6">
    {selectedBooking?.itemsData?.map((item, idx) => (
      <Card key={idx} sx={{ maxWidth: 220, height: 200, borderRadius: "8px", boxShadow: 3, overflowY: 'auto' }}>
        
        {/* Scrollable Image Section */}
        {item.service_image2 && (
          <div style={{ height: '100px', overflowY: 'auto' }}>
            <CardMedia
              component="img"
              image={`${API_URLS.getServiceImages.url}${item.service_image2}`}
              alt={item.service_name}
              sx={{
                objectFit: 'cover', 
                borderRadius: "8px 8px 0 0", 
                height: '100%',
                width: '100%', 
              }}
            />
          </div>
        )}

        {/* Scrollable Content Section */}
        <CardContent sx={{ textAlign: "center", padding: "12px", height: 'calc(100% - 120px)', overflowY: 'auto' }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold", color: colors.primary[100] }}>
            {item.service_name}
          </Typography>
          <Typography variant="body1" sx={{ color: colors.primary[200], margin: "6px 0" }}>
            ₹{item.service_price}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.primary[100], fontSize: "0.875rem" }}>
            {item.service_description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </div>
</DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={handleCloseModal}
            color="primary"
            className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-red-600"
            sx={{
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookingReport;
