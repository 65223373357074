import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import DistributorModal from "../components/DistributorModal";
import Distributors from "../components/Distributors";
import API_URLS from "../summaryapi/apiUrls";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";

const Distributor = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [distributors, setDistributors] = useState([]); // Renamed from serviceProviders to distributors
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(null);

  const fetchDistributors = async () => {
    try {
      const response = await axios.get(API_URLS.getAllDistributors.url); // Updated API URL for distributors
      if (response.data.status === false) {
        setStatusMessage("No distributors found.");
      } else {
        setDistributors(response.data.data || []); // Ensure distributors is always an array
      }
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch distributors.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDistributors();
  }, []);
  
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden"); // Lock body scroll
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock body scroll
    }

    // Clean up on component unmount or modal close
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]); // Runs every time modalOpen changes

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedDistributor(null); // Reset distributor on close
  };

  const handleModalOpen = (distributor) => {
    if (distributor) {
      // If the distributor is selected for editing, populate the form with its data
      setSelectedDistributor(distributor);
    } else {
      // If it's a new distributor, reset the form data
      setSelectedDistributor(null);
    }
    setIsModalOpen(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="relative m-4">
      <Typography
        variant="h4"
        component="h1"
        sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 4 }}
      >
        <h1 className="font-poppins font-bold text-center text-[30px]">Distributors</h1>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: "fixed",
          top: "20px",
          right: "20px",
          marginTop: "100px",
          zIndex: 9999,
        }}
        onClick={() => handleModalOpen(null)} // Open modal for adding
      >
        <Add /> Add Distributor
      </Button>

      {statusMessage ? (
        <div className="flex items-center justify-center h-screen">
          <span className="text-xl text-gray-500">{statusMessage}</span>
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 120px)", marginTop: "120px" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {/* Safely map over distributors array */}
              {distributors && Array.isArray(distributors) && distributors.map((distributor) => {
                if (!distributor || !distributor.distributor_id) return null; // Avoid rendering invalid distributor
                return (
                  <div
                    key={distributor.distributor_id} // Ensure key exists
                    className="distributor-card p-4 border rounded-lg shadow-lg flex flex-col justify-between"
                    style={{
                      width: "90%",
                      height: "600px",
                    }}
                  >
                    <Distributors {...distributor} />
                    <div className="text-center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleModalOpen(distributor)}
                      >
                        Modify Distributor
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* Pass the selected distributor data to the modal */}
      <DistributorModal
        open={isModalOpen}
        onClose={handleModalClose}
        distributor={selectedDistributor} // Changed from provider to distributor
        onSubmit={(newDistributor) => { // Updated to distributor
          if (selectedDistributor) {
            setDistributors(
              distributors.map((distributor) =>
                distributor?.distributor_id === newDistributor?.distributor_id
                  ? newDistributor
                  : distributor
              )
            );
          } else {
            setDistributors([...distributors, newDistributor]);
          }
          handleModalClose();
        }}
        fetchDistributors={fetchDistributors} // Fetch distributors after submission
      />
    </div>
  );
};

export default Distributor;
